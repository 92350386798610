import { ReactNode } from 'react'
import Link from 'next/link'

interface Props {
    children: ReactNode
    prefetch?: boolean
    onClick?: () => void
    href: string
    target?: '_blank' | '_self'
}

const DynamicLink = (props: Props) => {
    const isExternalLink = props.href.startsWith('https://') ?? false

    if (isExternalLink) {
        return (
            <a href={props.href} onClick={props.onClick} target={props.target}>
                {props.children}
            </a>
        )
    } else {
        return (
            <Link href={props.href} passHref prefetch={props.prefetch} legacyBehavior>
                <a target={props.target}>
                    <span onClick={props.onClick}>{props.children}</span>
                </a>
            </Link>
        )
    }
}

export default DynamicLink
