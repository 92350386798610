import { useMemo } from 'react'
import { TranslationKeys } from 'lib/features/translation'
import { TFunction, useTranslation } from 'next-i18next'
/**
  Memoizing Translation function due to useEffect duplicate calls.
*/
const _useTranslation = (key: TranslationKeys): { t: TFunction } => {
    const { t: _t } = useTranslation(key)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const t = useMemo(() => _t, []) // `t` should NOT be a hook dependency here.
    return { t }
}

export default _useTranslation
