import React, { ReactNode } from 'react'
import Modal from './Modal'

export interface Props {
    open: boolean
    onClose: () => void
    ariaPrefix: string
    title: string
    children: ReactNode
    hideTitle?: boolean
    disableBackdropClick?: boolean
    hideCloseButton?: boolean
    enableScrollLock?: boolean
    maxWidth?: number
    backgroundColor?: string
    hideBorderRadius?: boolean
    allowVerticalOverflow?: boolean
    allowHorizontalOverflow?: boolean
    dialogFooter?: ReactNode
    onScrollDown?: () => void
    fullScreen?: boolean
    dialogIcon?: ReactNode
    position?: 'top' | 'bottom'
    horizontalPosition?: 'left' | 'right'
    toggleFullScreen?: boolean
    noPadding?: boolean
    fullNoPadding?: boolean
}

const FeatureDialog = ({
    open,
    onClose,
    ariaPrefix,
    title,
    children,
    disableBackdropClick,
    hideCloseButton,
    enableScrollLock,
    backgroundColor,
    maxWidth,
    hideBorderRadius,
    hideTitle,
    allowVerticalOverflow,
    allowHorizontalOverflow,
    dialogFooter,
    onScrollDown,
    fullScreen,
    dialogIcon,
    position,
    toggleFullScreen,
    horizontalPosition,
    noPadding,
    fullNoPadding,
}: Props) => (
    <Modal
        show={open}
        onClose={onClose}
        aria-labelledby={`${ariaPrefix}-dialog`}
        maxWidth={maxWidth}
        disableBackdropClick={disableBackdropClick}
        title={title}
        hideTitle={hideTitle}
        enableScrollLock={enableScrollLock}
        hideCloseButton={hideCloseButton}
        backgroundColor={backgroundColor}
        hideBorderRadius={hideBorderRadius}
        allowVerticalOverflow={allowVerticalOverflow}
        allowHorizontalOverflow={allowHorizontalOverflow}
        dialogFooter={dialogFooter}
        onScrollDown={onScrollDown}
        fullScreen={fullScreen}
        dialogIcon={dialogIcon}
        position={position}
        horizontalPosition={horizontalPosition}
        toggleFullScreen={toggleFullScreen}
        noPadding={noPadding}
        fullNoPadding={fullNoPadding}
    >
        {children}
    </Modal>
)

export default FeatureDialog
