import Image, { ImageProps } from 'next/image'
import React from 'react'
import WhitePlaceholder from 'assets/placeholders/white.webp'

interface Props extends ImageProps {
    src: StaticImageData | string
    alt: string
    width?: number
    height?: number
    objectFit?: 'fill' | 'cover' | 'contain' | 'scale-down'
    className?: string
    lazyLoading?: boolean
}

const NextImage = ({ src, alt, lazyLoading, width, height, objectFit, layout, className, ...rest }: Props) => {
    const getSourceImage = () => {
        if (typeof src === 'string') {
            return src === '' ? WhitePlaceholder.src : src
        } else {
            return src?.src ?? ''
        }
    }
    return (
        <Image
            className={className}
            src={getSourceImage()}
            alt={alt}
            loading={lazyLoading ? 'lazy' : 'eager'}
            width={width}
            height={height}
            style={{ objectFit }}
            {...rest}
        />
    )
}
export default NextImage
