import { getCookieConsent } from 'lib/tracking/storage'

export enum SnapchatEvent {
    CustomEvent1 = 'CUSTOM_EVENT_1',
    PageView = 'PAGE_VIEW',
    Purchase = 'PURCHASE',
    SignUp = 'SIGN_UP',
}

const snapchatEventQueue: {
    type: SnapchatEvent
}[] = []

export function trackSnapchatEvent(event: SnapchatEvent) {
    if (typeof (window as any).snaptr === 'undefined') {
        console.warn('Unable to track Snapchat event.')
        return
    }

    const consentCookie = getCookieConsent()

    if (!consentCookie?.statistics || !consentCookie?.marketing) {
        snapchatEventQueue.push({ type: event })
        return
    }

    ;(window as any).snaptr('track', event)
}

export const emptySnapchatEventQueue = () => {
    if (!snapchatEventQueue?.length) return

    for (const event of snapchatEventQueue) {
        trackSnapchatEvent(event.type)
    }
    // https://stackoverflow.com/questions/1232040/how-do-i-empty-an-array-in-javascript
    snapchatEventQueue.length = 0
}
