export enum ErrorTypes {
    FormValidation = 'FormValidationError',
    ForbiddenError = 'ForbiddenError',
    NotFound = 'NotFoundError',
    InvalidCredentials = 'InvalidCredentialsError',
    Authorization = 'AuthorizationError',
    NoErrorType = 'NoErrorType',
    InvalidMIMEType = 'InvalidMIMETypeError',
    KnowDemandsReservationLimit = 'KnowDemandReservationLimitError',
    InvalidUsername = 'InvalidUsernameError',
    UsernameInUse = 'UsernameInUseError',
    EmailInUse = 'EmailInUseError',
    EmailConfirmationRequired = 'EmailConfirmationRequiredError',
    BadRequest = 'BadRequestError',
    Requires2FA = 'Requires2FAError',
    Invalid2FAToken = 'Invalid2FATokenError',
    RequiresCaptcha = 'RequiresCaptchaError',
    Duplicate = 'DuplicateError',
    NoTutoringMinutesAvailableError = 'NoTutoringMinutesAvailableError',
    TooManyLoginAttempts = 'TooManyLoginAttemptsError',
    TooManyRequests = 'TooManyRequestsError',
    KnowIsUndeletable = 'KnowIsUndeletableError',
    ContactCustomerSupport = 'ContactCustomerSupportError',
}
