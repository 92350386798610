import { CookieConsentData } from 'interfaces/CookieConsent'
import { CountryCode } from 'interfaces/Country'
import { FlashcardRequest } from 'interfaces/Flashcard'
import { Know, KnowCreateData } from 'interfaces/Know'
import { Attribution } from './attribution/attribution'
import { IpAddressLookupSnippet } from './ipAddressLookupTracking'
import { FeedEventOptions, SearchEventOptions } from 'interfaces/TrackingSnippets'

enum LocalStorageKey {
    ExpansionKnow = 'EXPANSION_KNOW_V2',
    CookieConsent = 'KNOWUNITY_COOKIE_CONSENT',
    OpenAppTimestamp = 'OPEN_APP_TIMESTAMP',
    AttributionSource = 'ATTRIBUTION_SOURCE',
    AttributionSourceCampaign = 'ATTRIBUTION_SOURCE_CAMPAIGN',
    AttributionExpiresOn = 'ATTRIBUTION_EXPIRES_ON',
    AttributionTikTokClickId = 'ATTRIBUTION_TIKTOK_CLICK_ID',
    AttributionFacebookClickId = 'ATTRIBUTION_FACEBOOK_CLICK_ID',
    DeviceIdentifier = 'DI',
    ExpansionUserSelectedRegion = 'EXPANSION_USER_SELECTED_REGION',
    SessionGenerationTimestamp = 'SESSION_GENERATION_TIMESTAMP',
    SessionTrackingUuid = 'SESSION_TRACKING_UUID',
    UploadAlternativeEmail = 'UPLOAD_ALTERNATIVE_EMAIL',
    LookupObject = 'LOOKUP',
    FlashcardArray = 'FLASHCARD_ARRAY',
    CountryCodeByIp = 'COUNTRY_CODE_BY_IP',
    FeedOption = 'FEED_OPTION',
    SearchOption = 'SEARCH_OPTION',
    WasUserSurveyShown = 'WAS_USER_SURVEY_SHOWN_1',
    WasFullScreenKnowBannerShown = 'WAS_FULL_SCREEN_KNOW_BANNER_SHOWN_1',
    AuthenticationOption = 'AUTHENTICATION_OPTION',
    WasUserAskedToSwitchDomain = 'WAS_USER_ASKED_TO_SWITCH_DOMAIN',
    DownloadKnowDocumentInfo = 'DOWNLOAD_KNOW_DOCUMENT_INFO',
}

export function storeExpansionKnow(expansionKnow: KnowCreateData) {
    if (!expansionKnow) {
        return
    } else {
        localStorage.setItem(LocalStorageKey.ExpansionKnow, JSON.stringify(expansionKnow))
    }
}

export function deleteExpansionKnow() {
    localStorage.removeItem(LocalStorageKey.ExpansionKnow)
}

export function getExpansionKnow() {
    const item = localStorage.getItem(LocalStorageKey.ExpansionKnow)
    if (!item) {
        return null
    }

    if (!item.startsWith('{') || !item.endsWith('}')) {
        return null
    }

    return JSON.parse(item) as KnowCreateData
}

// Cookie Consent
export function storeCookieConsent(data: CookieConsentData) {
    localStorage.setItem(LocalStorageKey.CookieConsent, JSON.stringify(data))
}

export function getCookieConsent(): CookieConsentData | null {
    const rawData = localStorage.getItem(LocalStorageKey.CookieConsent)
    if (rawData === null) {
        return null
    }
    return JSON.parse(rawData!)
}

// Open App Timestamp
export function storeOpenAppTimestamp(timestamp: string) {
    localStorage.setItem(LocalStorageKey.OpenAppTimestamp, timestamp)
}

export function getOpenAppTimestamp(): string | null {
    const timestamp = localStorage.getItem(LocalStorageKey.OpenAppTimestamp)
    if (timestamp === null) {
        return null
    }
    return timestamp
}

export function storeAttribution(expiresOn: Date, source: string, sourceCampaign: string) {
    localStorage.setItem(LocalStorageKey.AttributionExpiresOn, expiresOn.toISOString())
    localStorage.setItem(LocalStorageKey.AttributionSource, source)
    localStorage.setItem(LocalStorageKey.AttributionSourceCampaign, sourceCampaign)
}

export function getAttribution(): { source: string; sourceCampaign: string; expiresOn: Date } | null {
    const expiresOnRaw = localStorage.getItem(LocalStorageKey.AttributionExpiresOn)
    const source = localStorage.getItem(LocalStorageKey.AttributionSource)
    const sourceCampaign = localStorage.getItem(LocalStorageKey.AttributionSourceCampaign)

    if (!expiresOnRaw || !source || !sourceCampaign) {
        return null
    }

    return {
        expiresOn: new Date(expiresOnRaw),
        source,
        sourceCampaign,
    }
}

export function storeAttributionTikTokClickId(tiktokClickId: string) {
    localStorage.setItem(LocalStorageKey.AttributionTikTokClickId, tiktokClickId)
}

export function getAttributionTikTokClickId(): string | null {
    return localStorage.getItem(LocalStorageKey.AttributionTikTokClickId)
}

export function storeAttributionFacebookClickId(facebookClickId: string) {
    localStorage.setItem(LocalStorageKey.AttributionFacebookClickId, facebookClickId)
}

export function getAttributionFacebookClickId(): string | null {
    return localStorage.getItem(LocalStorageKey.AttributionFacebookClickId)
}

export function getDeviceIdentifier(): string | null {
    return localStorage.getItem(LocalStorageKey.DeviceIdentifier)
}

export function storeDeviceIdentifier(value: string) {
    localStorage.setItem(LocalStorageKey.DeviceIdentifier, value)
}

// Expansion User Region
export function getExpansionUserSelectedRegion(): number | null {
    const regionId = Number(localStorage.getItem(LocalStorageKey.ExpansionUserSelectedRegion))
    return regionId !== 0 && !isNaN(regionId) ? regionId : null
}

export function storeExpansionUserSelectedRegion(regionId: number) {
    localStorage.setItem(LocalStorageKey.ExpansionUserSelectedRegion, regionId.toString())
}

export function deleteExpansionUserSelectedRegion() {
    localStorage.removeItem(LocalStorageKey.ExpansionUserSelectedRegion)
}

// Session Timestamp
export function storeSessionGenerationTimestamp(timestamp: string) {
    localStorage.setItem(LocalStorageKey.SessionGenerationTimestamp, timestamp)
}

export function getSessionGenerationTimestamp(): string | null {
    const timestamp = localStorage.getItem(LocalStorageKey.SessionGenerationTimestamp)
    if (timestamp === null) {
        return null
    }
    return timestamp
}

export function storeSessionTrackingUuid(uuid: string) {
    localStorage.setItem(LocalStorageKey.SessionTrackingUuid, uuid)
}

export function getSessionTrackingUuid(): string | null {
    const sessionUuid = localStorage.getItem(LocalStorageKey.SessionTrackingUuid)
    if (sessionUuid === null) {
        return null
    }
    return sessionUuid
}

export const generateSessionSnippetObject = (currentSessionUuid: string, attributionData: Attribution) => ({
    uuid: currentSessionUuid,
    source: attributionData.source ?? null,
    sourceCampaign: attributionData.sourceCampaign ?? null,
})

// Upload Alternative Email
export function getUploadAlternativeEmail(): string | null {
    return localStorage.getItem(LocalStorageKey.UploadAlternativeEmail)
}

export function storeUploadAlternativeEmail(email: string) {
    localStorage.setItem(LocalStorageKey.UploadAlternativeEmail, email)
}

export function deleteUploadAlternativeEmail() {
    localStorage.removeItem(LocalStorageKey.UploadAlternativeEmail)
}

//Ip Adress Lookup Tracking
export function getIpAddressLookupSnippet(): IpAddressLookupSnippet | null {
    const snippet = localStorage.getItem(LocalStorageKey.LookupObject)
    if (snippet === null) {
        return null
    }
    return JSON.parse(snippet)
}

export function storeIpAddressLookupSnippet(snippet: IpAddressLookupSnippet) {
    localStorage.setItem(LocalStorageKey.LookupObject, JSON.stringify(snippet))
}

// Upload Alternative Email
export function getFlashcardArray(): FlashcardRequest[] | null {
    const flashcards = localStorage.getItem(LocalStorageKey.FlashcardArray)
    if (flashcards === null) {
        return null
    }
    return JSON.parse(flashcards)
}

export function storeFlashcardArray(flashcardArray: FlashcardRequest[]) {
    localStorage.setItem(LocalStorageKey.FlashcardArray, JSON.stringify(flashcardArray))
}

export function deleteFlashcardArray() {
    localStorage.removeItem(LocalStorageKey.FlashcardArray)
}

// User Country Code
export function storeUserCountryCodeFromIP(countryCode: CountryCode) {
    localStorage.setItem(LocalStorageKey.CountryCodeByIp, countryCode)
}

export function getUserCountryCodeFromIP(): CountryCode | null {
    return localStorage.getItem(LocalStorageKey.CountryCodeByIp) as CountryCode | null
}

// Feed Option
export function storeFeedOption(feed: FeedEventOptions) {
    localStorage.setItem(LocalStorageKey.FeedOption, JSON.stringify(feed))
}

export function getFeedOption(): FeedEventOptions | undefined {
    const feed = localStorage.getItem(LocalStorageKey.FeedOption)
    if (feed === null) {
        return undefined
    }
    return JSON.parse(feed)
}

export function deleteFeedOption() {
    localStorage.removeItem(LocalStorageKey.FeedOption)
}

// Search Option
export function storeSearchOption(search: SearchEventOptions) {
    localStorage.setItem(LocalStorageKey.SearchOption, JSON.stringify(search))
}

export function getSearchOption(): SearchEventOptions | undefined {
    const search = localStorage.getItem(LocalStorageKey.SearchOption)
    if (search === null) {
        return undefined
    }
    return JSON.parse(search)
}

export function deleteSearchOption() {
    localStorage.removeItem(LocalStorageKey.SearchOption)
}

// User survey
export function getWasUserSurveyShown(): string | null {
    return localStorage.getItem(LocalStorageKey.WasUserSurveyShown)
}

export function storeWasUserSurveyShown(applied: boolean) {
    localStorage.setItem(LocalStorageKey.WasUserSurveyShown, applied.toString())
}

// User survey
export function getWasFullScreenKnowBannerWasShown(): string | null {
    return localStorage.getItem(LocalStorageKey.WasFullScreenKnowBannerShown)
}

export function storeWasFullScreenKnowBannerWasShown(shown: boolean) {
    localStorage.setItem(LocalStorageKey.WasFullScreenKnowBannerShown, shown.toString())
}

// Authentication option
type AuthenticationOption = 'Google' | 'Apple ID' | 'Email'

export function getAuthenticationOption(): AuthenticationOption | null {
    const authenticationOption = localStorage.getItem(LocalStorageKey.AuthenticationOption)
    if (!authenticationOption) {
        return null
    }
    return authenticationOption as AuthenticationOption
}

export function storeAuthenticationOption(authenticationOption: AuthenticationOption) {
    localStorage.setItem(LocalStorageKey.AuthenticationOption, authenticationOption)
}

// Enter Correct Domain
export function getWasUserAskedToSwitchDomain(): string | null {
    return localStorage.getItem(LocalStorageKey.WasUserAskedToSwitchDomain)
}

export function storeWasUserAskedToSwitchDomain(declined: boolean) {
    localStorage.setItem(LocalStorageKey.WasUserAskedToSwitchDomain, declined.toString())
}

// User Subscription Download
export function getKnowToDownload(): Know | null {
    const know = localStorage.getItem(LocalStorageKey.DownloadKnowDocumentInfo)
    if (know === null) {
        return null
    }
    return JSON.parse(know)
}

export function storeKnowToDownload(know: Know) {
    localStorage.setItem(LocalStorageKey.DownloadKnowDocumentInfo, JSON.stringify(know))
}

export function deleteKnowToDownload() {
    localStorage.removeItem(LocalStorageKey.DownloadKnowDocumentInfo)
}
