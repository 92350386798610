import { ClientOnly } from 'components/elements/ClientOnly'
import { getLocales } from 'lib/constants/localization'
import Head from 'next/head'
import { useEffect, useState } from 'react'

const NoIndexAlternativeLocaleTag = () => {
    const [showNoIndexTag, setShowNoIndexTag] = useState(false)

    const localesPrefixes = Object.keys(getLocales())

    useEffect(() => {
        if (!localesPrefixes?.length) return

        // Example: '/en/about/press'
        const pathnameLocale = window.location.pathname.split('/')[1]
        if (localesPrefixes.includes(pathnameLocale)) {
            setShowNoIndexTag(true)
        }
    }, [])

    return (
        <ClientOnly>
            {showNoIndexTag ? (
                <Head>
                    <meta name="robots" content="noindex" />
                </Head>
            ) : null}
        </ClientOnly>
    )
}

export default NoIndexAlternativeLocaleTag
