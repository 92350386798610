import { CookieConsentData } from 'interfaces/CookieConsent'
import { getCookieConsent, storeCookieConsent } from 'lib/tracking/storage'
import { parseTranslationInterpolation } from 'lib/features/translation'
import Link from 'next/link'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import NewButton from 'components/elements/NewButton'
import theme from 'stories/utils/theme'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redux/reducer'
import { setOverwriteShowCookieBanner } from 'redux/actions'
import { Body1, ButtonText, CustomizableInlineText, Headline4 } from '../../../stories/elements/Typography/Text'
import _useTranslation from 'lib/hooks/_useTranslation'
import { emptyTiktokEventQueue } from 'lib/tracking/tiktok'
import { emptySnapchatEventQueue } from 'lib/tracking/snapchat'
import { emptyFacebookEventQueue } from 'lib/tracking/facebook'
import { getReferrerScreen } from 'lib/formatting/url'
import { useRouter } from 'next/router'
import { trackEvent } from 'lib/tracking/analytics-service'
import {
    AnalyticsEventCookieBannerAcceptAll,
    AnalyticsEventCookieBannerRejectAll,
    AnalyticsEventCookieBannerSaveSettings,
} from 'lib/constants/AnalyticsEventType'
import { getCountryConfig } from 'lib/constants/countryconfig'
import { parseLocale } from 'lib/constants/localization'
import { isBot } from 'lib/tracking/attribution/isbot'
import Stack from 'components/elements/Stack'
import SettingsIcon from 'assets/app/profile/settings.svg'
import { emptyGTMEventQueue } from 'lib/tracking/gtm'

const Wrapper = styled.div<{ reduceCookieBannerSize?: boolean }>`
    position: fixed;
    z-index: ${theme.zIndex.cookieBanner};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;

    @media (max-width: 750px) {
        top: auto;
        bottom: 0;
        height: auto;
        width: 100%;
    }
`

const Container = styled.div<{ reduceCookieBannerSize?: boolean }>`
    background: white;
    position: relative;
    width: 98%;
    max-width: 650px;
    padding: 30px;
    border-radius: ${theme.borderRadius.normal};
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    display: grid;
    gap: 20px;

    .texts {
        display: grid;
        gap: 20px;
    }
    & p,
    a {
        font-family: var(--font-inter);
        font-size: 0.8rem;
        line-height: 22px;
    }

    & a {
        color: ${theme.colors.deepSeaBlue};
        font-weight: 600;
    }

    & label {
        margin-left: 10px;
        p {
            position: relative;

            display: inline;
            font-size: 0.9rem;
        }
    }

    @media (max-width: 750px) {
        gap: 0;
        & h3 {
            font-size: 1em;
            margin-bottom: 5px;
            margin-top: 5px;
        }
        .texts {
            gap: 10px;
            p,
            a {
                padding-bottom: 15px;
                font-size: 0.7rem;
                line-height: 20px;
            }
        }

        border-radius: 0px;
        box-shadow: none;
        width: 100%;
        max-width: 100%;
        padding: 10px;
        border: 0;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    }
`

const ButtonWrapper = styled.div<{ showSettingsButton: boolean }>`
    display: grid;
    grid-gap: 10px;
    padding-top: 10px;
    .wrapper {
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr;
        place-content: space-between;
    }
    @media (max-width: 750px) {
        .wrapper {
            grid-template-columns: 1fr;
        }

        button {
            div {
                font-size: 10px;
            }
        }
    }
`

const SettingsLink = styled.p`
    cursor: pointer;
    position: absolute;
    right: 20px;
    bottom: 0;
`

const initialize = (data: CookieConsentData) => {
    // Fix duplicate initialization
    if (window.knowunityCookieInitialized) {
        return
    }

    window.knowunityCookieInitialized = true

    // empty third-party event queue
    if (data.statistics) {
        emptyTiktokEventQueue()
        emptySnapchatEventQueue()
        emptyFacebookEventQueue()
        emptyGTMEventQueue()
    }
}

function CookieBanner() {
    const [showBanner, setShowBanner] = useState(false)
    const [showSettings, setShowSettings] = useState(false)
    const [statisticsChecked, setStatisticsChecked] = useState(false)
    const [marketingChecked, setMarketingChecked] = useState(false)
    const { locale } = useRouter()
    const parsedLocale = parseLocale(locale)
    const countryConfig = getCountryConfig(parsedLocale.countryCode)

    const reduceCookieBannerSize = true

    const overwriteShowCookieBanner = useSelector((state: AppState) => state.overwriteShowCookieBanner)
    const { t } = _useTranslation('common')
    const dispatch = useDispatch()
    const router = useRouter()
    const referrerScreen = getReferrerScreen(router)

    useEffect(() => {
        if (!countryConfig.showCookieBanner) {
            const data = { required: true, statistics: true, marketing: true }
            storeData(data)
            initialize(data)
            return
        }

        const data = getCookieConsent()
        const isDataValid =
            data && ['required', 'statistics', 'marketing'].every((key) => data[key as keyof CookieConsentData] === true)

        if (data && isDataValid) {
            initialize(data)
        } else {
            setShowBanner(true)
            return
        }
        const userHasGivenConsent = data !== null
        setShowBanner(!userHasGivenConsent)
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setShowBanner])

    const handleStatisticsCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStatisticsChecked(event.target.checked)
    }

    const handleMarketingCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMarketingChecked(event.target.checked)
    }

    const handleSave = () => {
        const data = {
            required: true,
            statistics: statisticsChecked,
            marketing: marketingChecked,
        }
        trackEvent(AnalyticsEventCookieBannerSaveSettings, { referrerScreen })
        storeData(data)
        initialize(data)
    }

    const handleAcceptAll = () => {
        const data = { required: true, statistics: true, marketing: true }
        trackEvent(AnalyticsEventCookieBannerAcceptAll, { referrerScreen })
        storeData(data)
        initialize(data)
    }

    const handleRejectAll = () => {
        const data = { required: true, statistics: false, marketing: false }
        trackEvent(AnalyticsEventCookieBannerRejectAll, { referrerScreen })
        storeData(data)
        initialize(data)
    }

    const storeData = (data: CookieConsentData) => {
        setShowBanner(false)
        dispatch(setOverwriteShowCookieBanner(false))
        storeCookieConsent(data)
    }

    if ((!showBanner && !overwriteShowCookieBanner) || isBot()) {
        return null
    }

    return (
        <Wrapper data-cy="cookie-banner" reduceCookieBannerSize={reduceCookieBannerSize}>
            <Container reduceCookieBannerSize={reduceCookieBannerSize}>
                <span className="texts">
                    <Headline4 color={reduceCookieBannerSize ? theme.colors.deepSeaBlue : theme.colors.knowunityBlue}>{`${t(
                        'common/cookieBannerHeading'
                    )} 🍪`}</Headline4>
                    <Body1 fontFamily="var(--font-inter)" fontWeight={400}>
                        {parseTranslationInterpolation(t('common/cookieBannerText'), [
                            { index: 1, component: Link, props: { href: '/legal/privacy' } },
                        ])}{' '}
                        <CustomizableInlineText
                            hideCursor
                            color={theme.colors.deepSeaBlue}
                            fontFamily="var(--font-open-sans)"
                            fontWeight={600}
                        >
                            {reduceCookieBannerSize ? t('common/cookieBannerTextVariant') : null}
                        </CustomizableInlineText>
                    </Body1>

                    {!showSettings && !reduceCookieBannerSize ? (
                        <SettingsLink onClick={() => setShowSettings(true)}>
                            <Body1 color={theme.colors.knowunityBlue} fontWeight={800}>
                                → {t('common/cookieBannerSettingsButton')}
                            </Body1>
                        </SettingsLink>
                    ) : null}
                    {showSettings ? (
                        <form>
                            <div>
                                <input type="checkbox" name="requiredCookies" id="requiredCookies" checked disabled />
                                <label htmlFor="requiredCookies">
                                    <Body1>{t('common/cookieBannerRequiredCheckboxLabel')}</Body1>
                                </label>
                            </div>
                            <div>
                                <input
                                    type="checkbox"
                                    name="analyticsCookies"
                                    id="analyticsCookies"
                                    onChange={handleStatisticsCheckbox}
                                />
                                <label htmlFor="analyticsCookies">
                                    <Body1>{t('common/cookieBannerStatisticsCheckboxLabel')}</Body1>
                                </label>
                            </div>
                            <div>
                                <input
                                    type="checkbox"
                                    name="marketingCookies"
                                    id="marketingCookies"
                                    onChange={handleMarketingCheckbox}
                                />
                                <label htmlFor="marketingCookies">
                                    <Body1>{t('common/cookieBannerMarketingCheckboxLabel')}</Body1>
                                </label>
                            </div>
                        </form>
                    ) : null}
                </span>
                <ButtonWrapper showSettingsButton={showSettings}>
                    {showSettings ? (
                        <NewButton
                            type="secondary"
                            onClick={handleSave}
                            color={theme.colors.deepSeaBlue}
                            fullWidth={false}
                            borderRadius={theme.borderRadius.normal}
                            height={40}
                        >
                            <ButtonText
                                fontSize={12}
                                color={theme.colors.deepSeaBlue}
                                fontFamily="var(--font-inter)"
                                fontWeight={500}
                            >
                                {t('common/cookieBannerConfirmSelectionButton')}
                            </ButtonText>
                        </NewButton>
                    ) : null}
                    <Stack>
                        <div className="wrapper">
                            <NewButton
                                onClick={handleAcceptAll}
                                fullWidth={false}
                                borderRadius={theme.borderRadius.normal}
                                dataId="accept-all"
                                color={theme.colors.deepSeaBlue}
                            >
                                <ButtonText fontSize={16} color={theme.colors.white} fontFamily="var(--font-inter)">
                                    {t('common/cookieBannerAcceptAllButtonVariant')}
                                </ButtonText>
                            </NewButton>

                            <NewButton
                                type="secondary"
                                onClick={handleRejectAll}
                                fullWidth={false}
                                borderRadius={theme.borderRadius.normal}
                                color={theme.colors.deepSeaBlue}
                            >
                                <ButtonText fontSize={16} color={theme.colors.deepSeaBlue} fontWeight={700}>
                                    {t('common/cookieBannerRejectAllButton')}
                                </ButtonText>
                            </NewButton>
                        </div>
                        <SettingsLink onClick={() => setShowSettings((prev) => !prev)}>
                            <SettingsIcon />
                        </SettingsLink>
                    </Stack>
                </ButtonWrapper>
            </Container>
        </Wrapper>
    )
}

export default CookieBanner
