import { ParsedLocale } from 'lib/constants/localization'
import Arrow from 'assets/icons/menu_arrow.svg'
import ArrowDark from 'assets/icons/menu_arrow_dark.svg'
import styled, { css } from 'styled-components'
import { getCountryFlagIcon } from 'lib/features/country'
import { useToggleState } from 'lib/hooks/useToggleState'
import FeatureDialog from 'stories/elements/Modal/DialogWindow/FeatureDialog'
import CountrySwitcher from './CountrySwitcher'
import { useEffect, useState } from 'react'
import { Body2 } from 'stories/elements/Typography/Text'
import CountryService from 'services/CountryService'
import { ApiError } from 'services/ApiService'
import _useTranslation from 'lib/hooks/_useTranslation'
import { getUserCountryCodeFromIP, storeUserCountryCodeFromIP } from 'lib/tracking/storage'
import { CountryCode } from 'interfaces/Country'
import { ErrorTypes } from 'lib/constants/ErrorTypes'

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    svg:first-child {
        position: relative;
        top: 3px;
    }
    transition: all 0.3s ease-in-out;
`

const LocaleWrapper = styled.div`
    display: grid;
    width: 100%;
    place-content: center;
    padding: 25px 25px 0 25px;
    gap: 20px;
    transition: all 0.3s ease-in-out;
    transition: opacity 200ms linear, min-height 200ms linear;
`

const ArrowIcon = styled(Arrow)<{ isActive: boolean }>`
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -webkit-transition: -webkit-transform 0.35s ease-in-out;
    -ms-transition: -ms-transform 0.35s ease-in-out;
    transition: transform 0.35s ease-in-out;
    ${({ isActive }) =>
        isActive
            ? css`
                  transform: rotate(0deg);
                  -ms-transform: rotate(0deg);
                  -webkit-transform: rotate(0deg);
              `
            : ''}
`

const DarkArrowIcon = styled(ArrowDark)<{ isActive: boolean }>`
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -webkit-transition: -webkit-transform 0.35s ease-in-out;
    -ms-transition: -ms-transform 0.35s ease-in-out;
    transition: transform 0.35s ease-in-out;
    ${({ isActive }) =>
        isActive
            ? css`
                  transform: rotate(0deg);
                  -ms-transform: rotate(0deg);
                  -webkit-transform: rotate(0deg);
              `
            : ''}
`

interface Props {
    parsedLocale: ParsedLocale
    isDarkTheme?: boolean
    isLocatedInFooter?: boolean
}

const LocaleSwitcher = ({ parsedLocale, isDarkTheme, isLocatedInFooter }: Props) => {
    const [showLocaleOptionsDialog, toggleLocaleOptionDialog] = useToggleState(false)
    //const [showLanguageOptions, setShowLanguageOptions] = useState(false)
    const [showLocaleSwitcher, setShowLocaleSwitcher] = useState(false)
    const { t: tCommon } = _useTranslation('common')

    const handleUserCountryCodeMatchesLocale = (userCountryCode: CountryCode) =>
        setShowLocaleSwitcher(userCountryCode !== parsedLocale.countryCode)

    const fetchAndHandleCountryCodeOfUser = () =>
        CountryService.getByIp()
            .then((country) => {
                handleUserCountryCodeMatchesLocale(country.code)
                storeUserCountryCodeFromIP(country.code)
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    if (error.type === ErrorTypes.NotFound) {
                        console.warn('IP cannot be found')
                    } else {
                        error.handleUnknownError(tCommon, 'country.getByIp')
                    }
                } else {
                    throw error
                }
            })

    useEffect(() => {
        if (isLocatedInFooter) {
            setShowLocaleSwitcher(true)
            return
        }

        const userCountryCode = getUserCountryCodeFromIP()

        if (userCountryCode) {
            handleUserCountryCodeMatchesLocale(userCountryCode)
        } else {
            fetchAndHandleCountryCodeOfUser()
        }
    }, [])

    if (!parsedLocale || !showLocaleSwitcher) return null

    const onChange = () => {
        toggleLocaleOptionDialog()
    }

    const Icon = isDarkTheme ? ArrowIcon : DarkArrowIcon

    return (
        <div>
            <Container onClick={toggleLocaleOptionDialog}>
                <Body2>{getCountryFlagIcon(parsedLocale.countryCode)}</Body2>
                <Icon isActive={showLocaleOptionsDialog} />
            </Container>
            <FeatureDialog
                open={showLocaleOptionsDialog}
                onClose={toggleLocaleOptionDialog}
                ariaPrefix="locale-options"
                title=""
                hideTitle
                maxWidth={300}
            >
                <LocaleWrapper>
                    <CountrySwitcher parsedLocale={parsedLocale} onChanged={onChange} />
                </LocaleWrapper>
            </FeatureDialog>
        </div>
    )
}

export default LocaleSwitcher
