import { GlobalAuthService, GlobalAuthServiceWithoutToken } from './ApiService'
import { AuthToken } from 'interfaces/AuthToken'
import { setOauthToken, setKnower, setUser } from '../redux/actions'
import store from '../redux/store'

const TokenService = {
    loginWithRefreshToken(refreshToken: string) {
        return GlobalAuthServiceWithoutToken.post('oauth/token', {
            refreshToken,
        }).then((response) => {
            const data = response.data as AuthToken
            store().dispatch(setOauthToken(data))
            return data
        })
    },

    refreshToken() {
        const token = store().getState().oauthToken
        if (!token) {
            return Promise.reject('User does not have a token')
        }
        return this.loginWithRefreshToken(token.refreshToken)
    },

    logout() {
        const token = store().getState().oauthToken
        return GlobalAuthService.post('oauth/logout', { refreshToken: token?.refreshToken }).finally(() => {
            store().dispatch(setOauthToken(null))
            store().dispatch(setKnower(null))
            store().dispatch(setUser(null))
        })
    },
}

export default TokenService
