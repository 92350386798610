import UserService from 'services/UserService'
import { minutesBetweenDates } from '../formatting/datetime'
import { getIpAddressLookupSnippet, storeIpAddressLookupSnippet } from './storage'

export interface IpAddressLookupSnippet {
    lastUpdated: string
    updatedCount: number
}

const handleIpLookupRequest = (userUuid: string, snippet: IpAddressLookupSnippet) => {
    UserService.trackIpAdress(userUuid)
        .then(() => storeIpAddressLookupSnippet(snippet))
        .catch((error) => {
            console.error(error)
        })
}

export function handleIpAdressLookupTracking(userUuid: string) {
    let currentSnippet = getIpAddressLookupSnippet()

    if (currentSnippet === null) {
        currentSnippet = {
            lastUpdated: new Date().toISOString(),
            updatedCount: 1,
        }
        handleIpLookupRequest(userUuid, currentSnippet)

        return
    }

    const timeBetween = Math.abs(minutesBetweenDates(currentSnippet.lastUpdated, new Date().toISOString()))
    const isYoungerThan10Days = timeBetween / (60 * 24) < 10
    const isYoungerThan3Hours = timeBetween / 60 < 3

    const { updatedCount } = currentSnippet
    const isIpAdressTrackingSnippetFresh =
        (updatedCount <= 10 && isYoungerThan3Hours) || (updatedCount > 10 && isYoungerThan10Days)

    if (isIpAdressTrackingSnippetFresh) {
        return
    } else {
        currentSnippet = {
            lastUpdated: new Date().toISOString(),
            updatedCount: ++currentSnippet.updatedCount,
        }
        handleIpLookupRequest(userUuid, currentSnippet)

        return
    }
}
