import { getUrlParam } from 'lib/utils'
import {
    storeAttribution as storeAttributionStorage,
    getAttribution as getAttributionStorage,
    storeDeviceIdentifier as storeDeviceIdentifierStorage,
    getDeviceIdentifier as getDeviceIdentifierStorage,
    storeAttributionTikTokClickId,
    getAttributionTikTokClickId,
    storeAttributionFacebookClickId,
    getAttributionFacebookClickId,
} from 'lib/tracking/storage'
import { getDeviceIdentifier } from './device'

export interface Attribution {
    source?: string
    sourceCampaign?: string
    tiktokClickId?: string
    facebookClickId?: string
}

let cachedAttribution: Attribution | null = null

function getAttribution(): Attribution {
    if (typeof window === 'undefined') {
        return {}
    }

    if (cachedAttribution) {
        return cachedAttribution
    }

    const previousAttribution = getPreviousAttribution()
    const newAttribution = getNewAttribution()

    let attribution: Attribution = {}
    if (newAttribution && shouldOverwriteAttribution(previousAttribution, newAttribution)) {
        const addMinutes = (date: Date, minutes: number) => {
            return new Date(date.getTime() + minutes * 60000)
        }
        const expiresOn = addMinutes(new Date(), 30)

        if (newAttribution.source && newAttribution.sourceCampaign) {
            storeAttributionStorage(expiresOn, newAttribution.source, newAttribution.sourceCampaign)
        }
        attribution = newAttribution
    } else if (previousAttribution !== null) {
        attribution = previousAttribution
    }

    attribution.tiktokClickId = getTikTokAttribution() ?? undefined
    attribution.facebookClickId = getFacebookAttribution() ?? undefined

    cachedAttribution = attribution
    return attribution
}

function getPreviousAttribution() {
    const attributionStore = getAttributionStorage()
    if (!attributionStore) {
        return null
    }

    const isExpired = attributionStore.expiresOn < new Date()
    if (isExpired) {
        return null
    }

    return attributionStore
}

function getNewAttribution(): Attribution | null {
    const source = getSource()
    if (!source) {
        return null
    }

    const sourceCampaign = getSourceCampaign()

    return {
        source,
        sourceCampaign,
    }
}

function shouldOverwriteAttribution(previousAttribution: Attribution | null, newAttribution: Attribution): boolean {
    const attributionPriority: Record<string, number> = {
        share: 10,
        seo: 9,
    }

    if (previousAttribution === null) {
        return true
    }

    const previousPriority = previousAttribution.source ? attributionPriority[previousAttribution.source] : 0
    const newPriority = newAttribution.source ? attributionPriority[newAttribution.source] : 0
    return previousPriority === undefined || previousPriority <= newPriority
}

function getSource() {
    const referrer = getUrlParam('referrer')
    if (referrer) {
        return referrer
    }

    const utmSource = getUrlParam('utm_source')
    if (utmSource) {
        return utmSource
    }

    if (isFromSearchEngine()) {
        return 'seo'
    }

    return null
}

function getSourceCampaign() {
    const sid = getUrlParam('sid')
    if (sid) {
        return sid
    }

    const utmSource = getUrlParam('utm_campaign')
    if (utmSource) {
        return utmSource
    }

    return getDeviceIdentifier()
}

function getTikTokAttribution(): string | null {
    const tiktokClickid = getUrlParam('ttclid')
    if (tiktokClickid) {
        storeAttributionTikTokClickId(tiktokClickid)
        return tiktokClickid
    }

    return getAttributionTikTokClickId()
}

function getFacebookAttribution(): string | null {
    const facebookClickIdRaw = getUrlParam('fbclid')
    if (facebookClickIdRaw) {
        const facebookClickId = `fb.1.${Date.now()}.${facebookClickIdRaw}`
        storeAttributionFacebookClickId(facebookClickId)
        return facebookClickId
    }

    return getAttributionFacebookClickId()
}

function isFromSearchEngine() {
    // SQL query for finding those domains:
    // SELECT web_referrer, COUNT(distinct device_uuid)
    // FROM `knowunity_analytics.events`
    // WHERE event_timestamp >= date_sub(current_timestamp, INTERVAL 10 DAY) AND event_name = 'know_preview_view' AND platform = 'web' AND user_uuid IS NULL AND session_source IS NULL
    // GROUP BY 1
    // ORDER BY 2 DESC

    const domains = [
        // Google
        'https://www.google.com',
        'https://www.google.de',
        'https://www.google.at',
        'https://www.google.ch',
        'https://www.google.fr',
        'https://www.google.pl',
        'https://www.google.it',
        'https://www.google.co.uk',
        'https://www.google.es',
        'https://www.google.com.tr',
        'https://www.google.lu',
        'https://www.google.com.ua',
        'https://www.google.ca',
        'https://www.google.be',
        'https://www.google.ru',
        'https://www.google.com.hk',
        'https://www.google.ro',
        'https://www.google.nl',
        'https://www.google.gr',
        'https://www.google.ae',
        'https://www.google.co.jp',
        'https://www.google.com.au',
        'https://www.google.com.mx',
        'https://www.google.com.vn',
        'https://www.google.hu',
        'https://www.google.pt',
        'https://www.google.com.br',
        'https://www.google.se',
        'https://www.google.co.kr',
        'https://www.google.cl',
        'https://www.google.ie',
        'https://www.google.com.pe',
        'https://www.google.gg',
        'https://www.google.lt',
        'https://www.google.co.in',
        'https://www.google.dk',
        'https://www.google.co.za',
        'https://www.google.co.th',
        'https://www.google.com.sg',
        'https://www.google.no',
        'https://www.google.com.co',
        'https://www.google.com.ph',
        'https://www.google.ci',
        'https://www.google.as',
        'https://www.google.com.ar',
        'https://www.google.tn',
        'https://www.google.fi',
        'https://www.google.mu',
        'https://www.google.az',
        'https://www.google.co.nz',
        'https://www.google.co.ma',
        'https://www.google.kz',
        'https://www.google.com.ua',
        'https://www.google.by',
        'https://www.google.sn',
        'https://www.google.lv',
        'https://www.google.cd',
        'https://www.google.dj',
        'https://www.google.com.do',
        'android-app://com.google.android.googlequicksearchbox',
        'android-app://com.google.android.gm',

        // Yahoo
        'https://yahoo.com',
        'https://m.yahoo.com',
        'https://search.yahoo.com',
        'https://fr.search.yahoo.com',
        'https://it.search.yahoo.com',
        'https://pl.search.yahoo.com',
        'https://de.search.yahoo.com',
        'https://uk.search.yahoo.com',
        'https://es.search.yahoo.com',
        'https://at.search.yahoo.com',
        'https://ch.search.yahoo.com',

        // Other search engines
        'https://duckduckgo.com',
        'https://www.bing.com',
        'https://cn.bing.com',
        'https://startpage.com',
        'https://www.ecosia.org',
        'https://www.qwant.com',
        'https://www.qwantjunior.com',
        'https://lite.qwant.com',
        'https://search.brave.com',
        'https://yandex.ru',
        'https://afterdarkmode.com',
        'https://suche.t-online.de',
        'https://suche.web.de',
        'https://suche.gmx.net',
        'https://szukaj.onet.pl',
        'https://metager.de',
        'https://swisscows.com',
        'https://www.startfenster.de',
    ]

    const domainsWithTrailingSlash = domains.map((domain) => domain + '/')
    const finalDomains = domains.concat(domainsWithTrailingSlash)
    return finalDomains.includes(document.referrer)
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getAttribution,
    isFromSearchEngine,
}
