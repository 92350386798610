import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/reducer'
import { ApiError } from 'services/ApiService'
import UserService from 'services/UserService'
import { useTranslation } from 'next-i18next'
import { handleOpenAppTracking } from 'lib/tracking/openAppTracking'
import { handleIpAdressLookupTracking } from 'lib/tracking/ipAddressLookupTracking'
import { handleUserTimezone } from 'lib/tracking/userTimezone'

function AuthenticationHandler() {
    const authenticatedUser = useSelector((state: AppState) => state.user)
    const { t: tCommon } = useTranslation('common')

    useEffect(() => {
        if (authenticatedUser) {
            UserService.fetchUserAndKnower().catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknownError(tCommon, 'user.fetchUserAndKnower')
                } else {
                    throw error
                }
            })

            handleUserTimezone(authenticatedUser)
            handleOpenAppTracking(authenticatedUser.uuid)
            handleIpAdressLookupTracking(authenticatedUser.uuid)
        }
        // eslint-disable-next-line
    }, [])

    return null
}

export default AuthenticationHandler
