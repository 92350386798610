import { Know } from 'interfaces/Know'
import Router from 'next/router'

export function getUrlParam(name: string): string | null {
    // Any dummy host could be used here since only the part after the question mark will be used
    const url = new URL('https://knowunity.de' + Router.asPath)
    const urlParams = new URLSearchParams(url.search.slice(1))
    return urlParams.get(name)
}

export function setUrlParam(name: string, value: string, pushState?: boolean) {
    const url = new URL(window.location.toString())
    const urlParams = url.searchParams
    urlParams.set(name, value)
    if (pushState) {
        Router.push(url.toString())
    } else {
        Router.replace(url.toString())
    }
}

export function isDateInPast(date: string): boolean {
    const dateObject = new Date(date)
    const seconds = dateObject.getTime() / 1000
    const secondsLeft = seconds - Date.now() / 1000
    return secondsLeft < 0
}

/**
 * @deprecated The package lib/routes should be used instead.
 */
export function knowUrl(know: Know): string {
    return `/knows/${know.slug}`
}

export const loadScript = (src: string, onload: () => void) => {
    const script = document.createElement('script')
    script.async = false
    script.src = src
    const body = document.getElementsByTagName('body')[0]
    script.addEventListener('load', onload)
    body.appendChild(script)
}

export const emptyFunction = () => {
    // do nothing
}
