import { AxiosRequestConfig } from 'axios'
import config from 'lib/config'

const formatDateElementPadding = (element: number) => element.toString().padStart(2, '0')

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getMediaHeader = (apiConfig: AxiosRequestConfig<any>) => {
    // web platform
    const platform = 1

    const version = 1

    // path
    const path = apiConfig?.url ?? ''
    const pathWithLeadingSlash = path.startsWith('/') ? path : `/${path}`
    const pathLength = (142 + pathWithLeadingSlash.length).toString().padStart(5, '0')

    // time UTC YYYYMMDDHHMM
    const d = new Date()
    const timeFormat = [
        d.getUTCFullYear(),
        formatDateElementPadding(d.getUTCMonth() + 1),
        formatDateElementPadding(d.getUTCDate()),
        formatDateElementPadding(d.getUTCHours()),
        formatDateElementPadding(d.getUTCMinutes()),
        formatDateElementPadding(d.getUTCSeconds()),
    ].join('')
    const timeIdentifier = 3 * +timeFormat + 4321

    // random number
    const randomNumberMinimum = 1111
    const randomNumberMaximum = 9999
    const randomNumber = Math.floor(Math.random() * (randomNumberMaximum - randomNumberMinimum + 1)) + randomNumberMinimum

    // secret
    const secret = config.getApiSecretCode()
    const reversedSecret = secret.split('').reverse().join('')

    const header = `${platform}${version}${pathLength}${timeIdentifier}${randomNumber}${reversedSecret}`

    return header
}
