import { useEffect, useState } from 'react'
import Script from 'next/script'
import config from 'lib/config'

export const GoogleTagManager = () => {
    const [hasGclid, setHasGclid] = useState(false)

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        setHasGclid(!!urlParams.get('gclid'))
    }, [])

    if (!hasGclid) return null

    return (
        <>
            <Script
                src={`https://www.googletagmanager.com/gtag/js?id=${config.getGoogleTagManagerId()}`}
                strategy="afterInteractive"
            />
            <Script id="google-analytics" strategy="afterInteractive">
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${config.getGoogleTagManagerId()}');
                `}
            </Script>
        </>
    )
}
