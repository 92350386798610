import styled from 'styled-components'

interface Props {
    readonly gutter?: number
}

const Stack = styled.div<Props>`
    display: grid;
    gap: ${(props) => props.gutter ?? 16}px;
`

export default Stack
