import UserService from 'services/UserService'
import { minutesBetweenDates } from '../formatting/datetime'
import { getOpenAppTimestamp, storeOpenAppTimestamp } from './storage'

const REFRESH_TIMESTAMP_MINUTES_INTERVAL = 30

const handleOpenAppRequest = (userUuid: string) => {
    UserService.trackOpenApp(userUuid)
        .then(() => storeOpenAppTimestamp(new Date().toISOString()))
        .catch((error) => {
            console.error(error)
        })
}

const handleTimestampWasSend = (userUuid: string, lastTimestamp: string) => {
    const currentTimestamp = new Date()
    if (minutesBetweenDates(lastTimestamp, currentTimestamp) > REFRESH_TIMESTAMP_MINUTES_INTERVAL) {
        handleOpenAppRequest(userUuid)
    }
}

export const handleOpenAppTracking = (userUuid: string) => {
    const lastTimestamp = getOpenAppTimestamp()
    if (lastTimestamp) {
        handleTimestampWasSend(userUuid, lastTimestamp)
    } else {
        handleOpenAppRequest(userUuid)
    }
}
