import { Country } from 'interfaces/Country'
import countryLanguageConfigDev from './country-language-config.dev.json'
import countryLanguageConfigProd from './country-language-config.prod.json'
import { LanguageInterface } from 'interfaces/Language'
import { Environment, getEnvironment } from 'lib/config'
import { parseLocale } from './localization'

const configs = [
    {
        environment: Environment.Prod,
        source: countryLanguageConfigProd,
    },
    {
        environment: Environment.Dev,
        source: countryLanguageConfigDev,
    },
]

export type CountryLanguageConfig = {
    country: Country
    language: LanguageInterface
    domain: string
    supportEmail: string
    isLaunched: boolean
    isExpansionContestRunning: boolean
    instagramUrl: string
    tiktokUrl: string
    knowerGuideUrl: string
    knowerCallUrl: string
    expansionContestStartOn: string | null
    expansionContestEndOn: string | null
}

type CountryLanguageConfigJSON = {
    content: CountryLanguageConfig[]
}

export const getCountryLanguageConfig = (locale?: string): CountryLanguageConfig => {
    const parsedLocale = parseLocale(locale)
    const environment = getEnvironment() === Environment.Prod ? Environment.Prod : Environment.Dev

    const countryLanguageConfig = configs.find((config) => config.environment === environment)?.source

    if (!countryLanguageConfig) {
        throw new Error(
            `Cannot find countryLanguageConfig JSON file. Please run 'npm run config-dev' command to fetch the config file from the server.`
        )
    }

    const data = Object.values((countryLanguageConfig as CountryLanguageConfigJSON).content)
    const config = data.find(
        (config) =>
            config.language.code === parsedLocale.contentLanguageCode && config.country.code === parsedLocale.countryCode
    )

    if (!config) {
        throw new Error(`Unable to find country language config for ${locale} ${JSON.stringify(data)}`)
    }

    return config
}
