export function colorToCssFilter(hex: string) {
    // There is no easy way to change the color of an SVG icon that is loaded from a remote location.
    // Therefore, we have to use css filters to change the color. Since the algorithm is rather complex
    // to calculate the color, we are hard-coding the filter values here.
    // Reference: https://stackoverflow.com/a/53336754/7378726
    // Reference: https://codepen.io/sosuke/pen/Pjoqqp
    const colors: Record<string, string> = {
        '#0fd246': 'invert(58%) sepia(100%) saturate(840%) hue-rotate(84deg) brightness(93%) contrast(94%)',
        '#ffc01e': 'invert(69%) sepia(52%) saturate(697%) hue-rotate(353deg) brightness(106%) contrast(101%)',
        '#ff1887': 'invert(47%) sepia(92%) saturate(7055%) hue-rotate(318deg) brightness(100%) contrast(105%)',
        '#1882ff': 'invert(38%) sepia(83%) saturate(2700%) hue-rotate(199deg) brightness(101%) contrast(101%)',
        '#e8df44': 'invert(86%) sepia(44%) saturate(643%) hue-rotate(4deg) brightness(98%) contrast(94%)',
        '#000000': 'invert(0%) sepia(3%) saturate(0%) hue-rotate(238deg) brightness(99%) contrast(105%)',
        '#ffa401': 'invert(46%) sepia(99%) saturate(661%) hue-rotate(351deg) brightness(103%) contrast(107%)',
        '#36d9cf': 'invert(28%) sepia(100%) saturate(297%) hue-rotate(127deg) brightness(104%) contrast(60%)',
        '#7852ff': 'invert(54%) sepia(74%) saturate(7336%) hue-rotate(240deg) brightness(103%) contrast(101%)',
        '#2a46bd': 'invert(38%) sepia(93%) saturate(2700%) hue-rotate(197deg) brightness(81%) contrast(121%)',
        '#15803d': 'invert(23%) sepia(87%) saturate(2140%) hue-rotate(134deg) brightness(96%) contrast(84%)',
        '#c026d3': 'invert(22%) sepia(86%) saturate(4508%) hue-rotate(285deg) brightness(96%) contrast(91%)',
        '#2563eb': 'invert(29%) sepia(43%) saturate(3827%) hue-rotate(214deg) brightness(95%) contrast(94%)',
        '#f97316': 'invert(62%) sepia(70%) saturate(3611%) hue-rotate(348deg) brightness(96%) contrast(103%)',
        '#facc15': 'invert(96%) sepia(9%) saturate(6191%) hue-rotate(341deg) brightness(97%) contrast(102%)',
    }

    const filter = colors[hex.toLowerCase()]
    return filter || colors['#000000']
}

export function complimentingColor(hex: string) {
    const colors: Record<string, string> = {
        '#0fd246': '#cef7da',
        '#ffc01e': '#fff4d7',
        '#ff1887': '#fed1e4',
        '#1882ff': '#e6f2ff',
        '#e8df44': '#faffb0',
        '#ffa401': '#f8ebd4',
        '#000000': ' ',
    }
    const filter = colors[hex.toLowerCase()]
    return filter || colors['#000000']
}
