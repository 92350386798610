import { ParsedLocale } from 'lib/constants/localization'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { getCountryFlagIcon } from 'lib/features/country'
import { languageDomains } from 'lib/constants/language'
import { languageToCountryCode } from 'lib/formatting/language'
import { Body2 } from 'stories/elements/Typography/Text'
import theme from 'stories/utils/theme'
import { returnCountrySwitcherLabelByContentLanguage } from 'lib/constants/localizedCountryLabels'

const Container = styled.div`
    width: 100%;
    display: grid;
    gap: 15px;
    .option {
        cursor: pointer;
        display: grid;
        padding-left: 25px;
        grid-template-columns: 50px 1fr;
        &:hover {
            p {
                color: ${theme.colors.deepSeaBlue};
            }
        }
    }
`

interface Props {
    parsedLocale: ParsedLocale
    onChanged: () => void
}

const CountrySwitcher = ({ parsedLocale, onChanged }: Props) => {
    const router = useRouter()
    const { asPath } = router

    if (!parsedLocale) return null

    const countryOptions = Object.values(languageDomains).map((v) => ({
        data: v,
        label: returnCountrySwitcherLabelByContentLanguage({ contentLanguageCode: v } as ParsedLocale),
    }))

    const handleUpdateLocale = (data: string) => {
        onChanged()
        router.push(asPath, asPath, { locale: data })
    }

    return (
        <Container>
            {countryOptions.map((option) => (
                <div className="option" key={`country-${option.label}`}>
                    {getCountryFlagIcon(languageToCountryCode(option.data))}
                    <Body2
                        key={option.label}
                        onClick={() => handleUpdateLocale(option.data)}
                        color={theme.colors.body1Black}
                    >
                        {option.label}
                    </Body2>
                </div>
            ))}
        </Container>
    )
}

export default CountrySwitcher
