import { AnalyticsEventGtagConversionEventFailed } from 'lib/constants/AnalyticsEventType'
import { trackEvent } from './analytics-service'
import { getCookieConsent } from './storage'
import config from 'lib/config'

interface GTMItem {
    item_id: string
    item_name: string
    item_category?: string
    item_variant?: string
    item_brand?: string
    price?: number
    quantity?: number
}

export interface GTMEvent {
    send_to: string
    currency?: string
    value?: number
    transaction_id?: string
    items?: GTMItem[]
    category?: string
    action?: string
    label?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
}

const gtmEventQueue: {
    event: GTMEvent
}[] = []

export function trackGTMEvent(event: GTMEvent) {
    if (!window.gtag) {
        console.error('gtag is not defined')
        trackEvent(AnalyticsEventGtagConversionEventFailed, {})
        return
    }
    const consentCookie = getCookieConsent()

    if (!consentCookie?.statistics) {
        gtmEventQueue.push({ event })
        return
    }

    const { send_to, ...rest } = event

    window.gtag('event', 'conversion', {
        send_to: `${config.getGoogleTagManagerId()}/${send_to}`,
        ...rest,
    })
}

export const emptyGTMEventQueue = () => {
    if (!gtmEventQueue?.length) return

    for (const event of gtmEventQueue) {
        trackGTMEvent(event.event)
    }
    // https://stackoverflow.com/questions/1232040/how-do-i-empty-an-array-in-javascript
    gtmEventQueue.length = 0
}
