import { SearchSuggestionTypes } from 'interfaces/Search'
import { Subject } from 'interfaces/Subject'
import { TFunction } from 'i18next'

export const generateDefaultSearchSuggestion = (text: string, subject: Partial<Subject>) => ({
    numberOfResults: 0,
    numberOfSearches: 0,
    subject: {
        id: subject?.id,
        ...subject,
    },
    text,
    type: 'TEXT' as SearchSuggestionTypes,
})

export const formatSearchResultCountText = (searchResultCount: number | null, t: TFunction<'app'>) => {
    switch (true) {
        case searchResultCount === null:
            return ''
        case searchResultCount! >= 10000:
            return `${searchResultCount}+ ${t('app/searchResultsLabel')}`
        case searchResultCount === 0:
            return t('app/searchNoResultsLabel')
        default:
            return `${searchResultCount} ${t('app/searchResultsLabel')}`
    }
}
