import { Knower } from 'interfaces/Knower'
import { SET_OAUTH_TOKEN, ActionTypes, SET_KNOWER, SET_USER, SET_OVERWRITE_SHOW_COOKIE_BANNER } from './types'
import { AuthToken } from 'interfaces/AuthToken'
import { User } from 'interfaces/User'

export const setOauthToken = (token: AuthToken | null): ActionTypes => ({
    type: SET_OAUTH_TOKEN,
    payload: token,
})

export const setKnower = (knower: Knower | null): ActionTypes => ({
    type: SET_KNOWER,
    payload: knower,
})

export const setUser = (user: User | null): ActionTypes => ({
    type: SET_USER,
    payload: user,
})

export const setOverwriteShowCookieBanner = (value: boolean): ActionTypes => ({
    type: SET_OVERWRITE_SHOW_COOKIE_BANNER,
    payload: value,
})
