const GRID_BREAKPOINT = {
    SMALL: '576px',
    MEDIUM: '750px',
    LAPTOP_HEIGHT: '800px',
    LARGE: '1050px',
    TABLET_VERTICAL: '1150px',
    TABLET: '1250px',
    EXTRA_LARGE: '1440px',
    FULL_WIDTH: '1680px',
    FULL_HD: '2048px',
}

export default GRID_BREAKPOINT

export const deviceSize = {
    sm: `(max-width: ${GRID_BREAKPOINT.SMALL})`,
    md: `(max-width: ${GRID_BREAKPOINT.MEDIUM})`,
    lg: `(max-width: ${GRID_BREAKPOINT.LARGE})`,
    tabletVertical: `(max-width: ${GRID_BREAKPOINT.TABLET_VERTICAL})`,
    tablet: `(max-width: ${GRID_BREAKPOINT.TABLET})`,
    xl: `(max-width: ${GRID_BREAKPOINT.EXTRA_LARGE})`,
    minXL: `(min-width: ${GRID_BREAKPOINT.EXTRA_LARGE})`,
    fullWidth: `(max-width: ${GRID_BREAKPOINT.FULL_WIDTH})`,
    fullHD: `(min-width: ${GRID_BREAKPOINT.FULL_HD})`,
    mdHeight: `(max-height: ${GRID_BREAKPOINT.MEDIUM})`,
    lgHeight: `(max-height: ${GRID_BREAKPOINT.LAPTOP_HEIGHT})`,
}
