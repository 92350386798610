import { useMemo } from 'react'
import { createStore, applyMiddleware, Store } from 'redux'
import reducer, { initialState } from './reducer'
import { ActionTypes, State } from './types'

let store: Store<State, ActionTypes>

function initStore(preloadedState = initialState) {
    return createStore(reducer, preloadedState, applyMiddleware())
}

export const initializeStore = (preloadedState: State) => {
    const _store = store ?? initStore(preloadedState)

    if (typeof window === 'undefined') return _store
    // Create the store once in the client
    if (!store) store = _store

    return _store
}

export function useStore(initialState: State) {
    const store = useMemo(() => initializeStore(initialState), [initialState])
    return store
}

export default function getStore() {
    return store
}
