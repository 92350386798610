import ApiService, { ApiError, ApiServiceWithoutToken } from './ApiService'
import store from '../redux/store'
import config from 'lib/config'
import { TrackingEvent } from 'interfaces/TrackingEvent'

const AnalyticsService = {
    trackEvent(data: TrackingEvent) {
        const token = store().getState().oauthToken
        let service = ApiServiceWithoutToken
        if (token) {
            service = ApiService
        }

        const baseURL = config.getAnalyticsServiceBaseUrl()

        return service.post('/events', data, { baseURL }).catch((error) => {
            if (error instanceof ApiError) {
                console.error('An error occurred while sending event.')
            } else {
                throw error
            }
        })
    },

    trackEventBeacon(data: TrackingEvent) {
        const token = store().getState().oauthToken
        const headers = {
            'Content-Type': 'application/json',
            Authorization: token ? `Bearer ${token}` : null,
        } as BlobPropertyBag
        // This is necessary since normally BlobPropertyBag expects an attribute called `type`.
        // However, if we add the attribute, the request is not being sent to the server.

        const blob = new Blob([JSON.stringify(data)], headers)
        const url = `${config.getAnalyticsServiceBaseUrl()}/events`
        return navigator.sendBeacon(url, blob)
    },

    trackEventServerSide(data: TrackingEvent) {
        const baseURL = config.getAnalyticsServiceBaseUrl()

        // We need to use fetch instead of axios because axios is not supported in the middleware.
        // https://github.com/vercel/next.js/discussions/56322
        return fetch(`${baseURL}/events`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                if (!response.ok) {
                    console.error('An error occurred while tracking event server side.', response.statusText)
                }
            })
            .catch((error) => {
                console.error('An error occurred while tracking event server side.', error)
            })
    },
}

export default AnalyticsService
