import ApiService, { GlobalAuthService } from './ApiService'
import { NewUserPassword, User } from 'interfaces/User'
import { UpdateUserRequest } from 'interfaces/UpdateUserRequest'
import { setUser } from '../redux/actions'
import store from '../redux/store'
import KnowerService from './KnowerService'
import { getRequestConfig, RequestParameters } from './RequestParameters'

const UserService = {
    getMe(requestParameters: RequestParameters = {}, updateStore = true): Promise<User> {
        return ApiService.get('users/me', getRequestConfig(requestParameters)).then((response) => {
            if (updateStore) {
                store().dispatch(setUser(response.data))
            }
            return response.data as User
        })
    },

    get(userUuid: string) {
        return ApiService.get(`users/${userUuid}/public`).then((response) => {
            return response.data as User
        })
    },

    update(user: UpdateUserRequest, o?: { skipStoreDispatch: boolean }): Promise<User> {
        const userUuid = store().getState().user?.uuid

        return ApiService.patch(`users/${userUuid}`, {
            ...user,
        }).then((response) => {
            !o?.skipStoreDispatch && store().dispatch(setUser(response.data))
            return response.data as User
        })
    },

    updateSchool(user: User, schoolId: number): Promise<User> {
        const userUuid = store().getState().user?.uuid

        return ApiService.patch(`users/${userUuid}`, {
            ...user,
            school: {
                id: schoolId,
            },
        }).then((response) => {
            store().dispatch(setUser(response.data))
            return response.data as User
        })
    },

    getProfilePicture(uuid: string) {
        return ApiService.get(`users/${uuid}/profilepicture`, {
            responseType: 'blob',
        }).then((response) => {
            const blob = new Blob([response.data], {
                type: response.headers['content-type'],
            })
            return URL.createObjectURL(blob)
        })
    },

    updateProfilePicture(uuid: string, file: Blob) {
        const formatData = new FormData()
        formatData.append('file', file)
        return ApiService.post(`users/${uuid}/profilepicture`, formatData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then((response) => {
            store().dispatch(setUser(response.data))
            return response.data as User
        })
    },

    resendConfirmationMail() {
        const userUuid = store().getState().user?.uuid
        return GlobalAuthService.post(`/accounts/${userUuid}/resendconfirmation`)
    },

    confirmUser(token: string) {
        return GlobalAuthService.post('accounts/confirm-email', {
            confirmationToken: token,
        }).then((response) => {
            const user = store().getState().user
            if (user) {
                store().dispatch(setUser({ ...user, active: true }))
            }

            return response
        })
    },

    createUser(email: string, password: string, name: string, source: string | null, sourceCampaign: string | null) {
        return ApiService.post('users', {
            email,
            name,
            password,
            source,
            sourceCampaign,
        }).then((response) => {
            return response.data as User
        })
    },

    fetchUserAndKnower() {
        return this.getMe().then((user) => {
            if (!user.knowerUuid) {
                return user
            }

            return KnowerService.getMe().then(() => user)
        })
    },

    changeUsername(userUuid: string, newUsername: string) {
        return ApiService.patch(`/users/${userUuid}/username?username=${newUsername}`)
    },

    changeEmail(userUuid: string, newEmail: string, password: string) {
        return GlobalAuthService.patch(`/accounts/${userUuid}/email`, { newEmail, password }).then((response) => {
            return response.data as User
        })
    },

    changePassword(userUuid: string, { newPassword, oldPassword }: NewUserPassword) {
        return GlobalAuthService.patch(`/accounts/${userUuid}/password`, { newPassword, oldPassword }).then((response) => {
            return response.data as User
        })
    },

    unsubscribeNewsletter(emailPreferencesToken: string, emailUuid: string, emailType: string) {
        return ApiService.post('/users/newsletter/unsubscribe/v2', {
            emailType,
            emailPreferencesToken,
            emailUuid,
        })
    },

    getUserByUsername(username: string) {
        return ApiService.get(`users/usernames/${username}`).then((response) => {
            return response.data as User
        })
    },

    trackOpenApp(userUuid: string) {
        return ApiService.post(`users/${userUuid}/open-app`, userUuid)
    },

    trackIpAdress(userUuid: string) {
        return ApiService.post(`users/${userUuid}/lookup`, userUuid)
    },

    trackUserTimezone(userUuid: string, offset: number) {
        return ApiService.patch(`users/${userUuid}/timezone`, { offset })
    },
}

export default UserService
