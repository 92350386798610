import { User } from 'interfaces/User'
import UserService from 'services/UserService'

const getUTCTimezoneOffset = () => new Date().getTimezoneOffset() / 60

export const handleUserTimezone = ({ utcTimeOffset, uuid }: User) => {
    const timezoneOffset = getUTCTimezoneOffset()
    if (utcTimeOffset === timezoneOffset) return

    UserService.trackUserTimezone(uuid, timezoneOffset).catch((error) => {
        console.error(error)
    })
}
