import attribution from 'lib/tracking/attribution/attribution'
import { getAdjustTrackerCodeAndroid, getAdjustTrackerCodeIos } from 'lib/config'
import { appDeepLinkHomeScreen } from './app-deep-links'
import { getMobileOS } from 'lib/tracking/attribution/device'
import { getAdjustRedirectCPPLink, getAdjustTrackerCPPCode } from './adjust-links'

export const appDownloadUrl = (platform: 'dynamic' | 'android' | 'ios', referrerScreen: string, deepLinkPath?: string) =>
    getAdjustLink(platform, referrerScreen, deepLinkPath)

// Redirect to app download after 200 ms. This can be used to track an event before redirecting the user.
export const redirectToAppDownloadUrlAsync = (
    platform: 'dynamic' | 'android' | 'ios',
    referrerScreen: string,
    deepLinkPath?: string
) => {
    setTimeout(() => {
        const url = appDownloadUrl(platform, referrerScreen, deepLinkPath)

        window.location.href = url
    }, 200)
}

export const getDynamicAppLink = (path: string, campaign: string) => {
    const encodedUrl = encodeURIComponent(path)
    return `https://a.knowunity.de/app?link=${encodedUrl}&source=website&campaign=${campaign}&path=${encodedUrl}&originIdentifier=`
}

export const getAdjustLink = (platform: 'dynamic' | 'android' | 'ios', referrerScreen: string, deepLinkPath?: string) => {
    const { source, sourceCampaign } = attribution.getAttribution()

    const fullDeepLinkPath = getAdjustFullDeepLink(referrerScreen, deepLinkPath, source, sourceCampaign)
    const encodedDeepLinkPath = encodeURIComponent(fullDeepLinkPath)

    let trackerCode
    switch (platform) {
        case 'dynamic':
            trackerCode = `${getAdjustTrackerCodeIos()}_${getAdjustTrackerCodeAndroid()}`
            break
        case 'ios':
            trackerCode = getAdjustTrackerCodeIos()
            break
        case 'android':
            trackerCode = getAdjustTrackerCodeAndroid()
            break
    }

    const url = `https://app.adjust.com/${trackerCode}?campaign=${source}&adgroup=${sourceCampaign}&deep_link=knowunity://${encodedDeepLinkPath}`
    return url
}

export const getAdjustCPPLink = (referrerScreen: string, isVariant: boolean, deepLinkPath?: string) => {
    const { source, sourceCampaign } = attribution.getAttribution()
    const isSystemIos = getMobileOS() === 'ios'

    // adjust CPP link elements
    const adjustRedirect = getAdjustRedirectCPPLink(isSystemIos, isVariant)
    const trackerCode = getAdjustTrackerCPPCode(isSystemIos, isVariant)

    // deep link elements
    const fullDeepLinkPath = getAdjustFullDeepLink(referrerScreen, deepLinkPath, source, sourceCampaign)
    const encodedDeepLinkPath = encodeURIComponent(fullDeepLinkPath)

    const url = `https://app.adjust.com/${trackerCode}?redirect=${adjustRedirect}&campaign=${source}&adgroup=${sourceCampaign}&deep_link=knowunity://${encodedDeepLinkPath}`

    return url
}

export const redirectToAppUsingCPPAdjustLink = (referrerScreen: string, isVariant: boolean, deepLinkPath?: string) => {
    setTimeout(() => {
        const url = getAdjustCPPLink(referrerScreen, isVariant, deepLinkPath)
        window.location.href = url
    }, 200)
}

function getAdjustFullDeepLink(
    referrerScreen: string,
    deepLinkPath?: string,
    source?: string | null,
    sourceCampaign?: string | null
) {
    const referrer = `website-${referrerScreen}`

    if (!deepLinkPath) {
        deepLinkPath = appDeepLinkHomeScreen
    }

    return removeLeadingSlash(`${deepLinkPath}?source=${source}&campaign=${sourceCampaign}&referrer=${referrer}`)
}

function removeLeadingSlash(value: string) {
    // Remove leading '/'
    if (value.startsWith('/')) {
        return value.substring(1)
    }
    return value
}
