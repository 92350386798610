import {
    getAdjustTrackerCppRedirectAndroidVariant,
    getAdjustTrackerCppRedirectAndroidBaseline,
    getAdjustTrackerCppRedirectIosVariant,
    getAdjustTrackerCppRedirectIosBaseline,
    getAdjustTrackerCppCodeAndroidBaseline,
    getAdjustTrackerCppCodeAndroidVariant,
    getAdjustTrackerCppCodeIosBaseline,
    getAdjustTrackerCppCodeIosVariant,
} from 'lib/config'

export const getAdjustRedirectCPPLink = (isSystemIos: boolean, isVariant: boolean) => {
    const androidRedirect = isVariant
        ? getAdjustTrackerCppRedirectAndroidVariant()
        : getAdjustTrackerCppRedirectAndroidBaseline()
    const iosRedirect = isVariant ? getAdjustTrackerCppRedirectIosVariant() : getAdjustTrackerCppRedirectIosBaseline()
    const redirect = isSystemIos ? iosRedirect : androidRedirect
    return encodeURIComponent(redirect)
}

export const getAdjustTrackerCPPCode = (isSystemIos: boolean, isVariant: boolean) => {
    const androidTrackerCode = isVariant ? getAdjustTrackerCppCodeAndroidVariant() : getAdjustTrackerCppCodeAndroidBaseline()
    const iosTrackerCode = isVariant ? getAdjustTrackerCppCodeIosVariant() : getAdjustTrackerCppCodeIosBaseline()

    return isSystemIos ? iosTrackerCode : androidTrackerCode
}
