import styled from 'styled-components'
import theme from 'stories/utils/theme'

export const Container = styled.footer`
    width: 100%;
    min-height: 400px;
    background: ${theme.colors.deepSeaBlue};
    padding: 20px 5%;
    display: grid;
    grid-gap: 15px;
    z-index: ${theme.zIndex.navBar};
`

export const Grid = styled.div`
    display: grid;
    grid-template-columns: auto 1fr 2fr 2fr 2fr;

    @media (max-width: 1150px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 750px) {
        display: block;
    }
`

export const FirstColumn = styled.div`
    position: relative;
    min-height: 400px;
    & .title {
        font-size: 23px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    & .slogan {
        font-size: 20px;
        margin-top: 0;
    }
`

export const LinkColumn = styled.div`
    & ul {
        list-style-type: none;
        padding: 0;
    }

    & li {
        margin: 5px 0;
    }

    & a,
    & .fake-link {
        cursor: pointer;
        color: ${theme.colors.white};
        opacity: 0.75;
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        :hover {
            color: ${theme.colors.lightCaption};
        }
    }

    & strong {
        color: ${theme.colors.white};
    }
    @media (max-width: 750px) {
        & a,
        & .fake-link {
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
        }
        & strong {
            font-size: 20px;
            font-weight: 700;
            line-height: 30px;
            letter-spacing: 0em;
        }
    }
`

export const DownloadGrid = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 375px;
    position: relative;
    top: 175px;
    left: 50px;
    gap: 20px;
    & a,
    & .fake-link {
        width: 100%;
        max-width: 175px;
    }
    @media (max-width: 750px) {
        top: 125px;
        width: 100%;
        left: 0;
        flex-wrap: wrap;
        justify-content: center;
    }
    @media (max-width: 450px) {
        gap: 10px;
        top: 125px;
        width: 100%;
        display: grid;
    }
`

export const CopyrightGrid = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    border-top: 1px solid ${theme.colors.lightCaption};

    h6 {
        color: ${theme.colors.white};
    }
    .switcher {
        display: flex;
        gap: 15px;
        align-items: center;
    }
    @media (max-width: 1150px) {
        flex-wrap: wrap;
    }
`

export const ButtonWrapper = styled.div`
    position: absolute;
    bottom: 6px;
    left: 0;
    background-color: ${theme.colors.accentBlue};
`

export const FooterFrameComponent = styled.div`
    position: absolute;
    top: 30px;

    @media (max-width: 400px) {
        img {
            width: 80vw;
            height: auto;
        }
    }
`

export const SocialIcons = styled.div`
    display: flex;
    gap: 10px;
    & svg {
        fill: #949494;
        width: 25px;
        height: 25px;
    }
`
