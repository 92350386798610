import { referrerScreenQueryKey } from 'lib/constants/referrerScreen'
import { GetServerSidePropsContext } from 'next'
import { NextRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'

export function getPathWithOldQueryParams(path: string, oldUrl: string) {
    if (!oldUrl.includes('?')) {
        return path
    }
    return `${path}?${oldUrl.split('?')[1]}`
}

export function getPathFromUrl(url: string) {
    return url.split('?')[0]
}

export function getHashFromUrl() {
    const hash = window.location.hash
    if (!hash) return null
    return hash.split('?')[0]
}

export default function isValidHttpUrl(message: string): boolean {
    let url

    try {
        url = new URL(message)
    } catch (_) {
        return false
    }

    return url.protocol === 'http:' || url.protocol === 'https:'
}

export function parseIdentifierFromSlug(slug: string): string | number | null {
    const uuid = parseUuidFromSlug(slug)
    if (uuid) {
        return uuid
    }

    return parseIdFromSlug(slug)
}
export function isUUID(value: string) {
    const match = value.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$')
    return match !== null
}

export function parseUuidFromSlug(slug: string): string | null {
    const lengthOfUUID = 36
    const possibleUUID = slug.substr(slug.length - lengthOfUUID)
    if (isUUID(possibleUUID)) {
        return possibleUUID
    }
    return null
}

export function parseIdFromSlug(slug: string): number | null {
    const slugParts = slug.split('-')
    const lastPart = slugParts[slugParts.length - 1]
    const id = parseInt(lastPart)
    if (isNaN(id)) {
        return null
    }
    return id
}

export function getUrlParamServerSide(name: string, context: GetServerSidePropsContext<ParsedUrlQuery>): string | null {
    const path = getPathFromContext(context)

    // Any dummy host could be used here since only the part after the question mark will be used
    const url = new URL('https://knowunity.de' + path)
    const urlParams = new URLSearchParams(url.search.slice(1))
    return urlParams.get(name)
}

export function getPathFromContext(context: GetServerSidePropsContext<ParsedUrlQuery>): string {
    // Any dummy host could be used here since only the part after the question mark will be used
    const url = new URL('https://knowunity.de' + context.resolvedUrl)
    const urlParams = url.searchParams

    if (!context.params) {
        return context.resolvedUrl
    }

    for (const param of Object.keys(context.params)) {
        urlParams.delete(param)
    }

    return url.pathname + url.search
}

export function getReferrerScreen(router: NextRouter) {
    return getQueryParamRouter(router, referrerScreenQueryKey)
}

export const getQueryParamRouter = (router: NextRouter, key: string) => {
    const referrerScreen = router.query[key]
    if (typeof referrerScreen === 'string') {
        return referrerScreen
    }
    return undefined
}

export const doesLinkIncludeAttachment = (url: string) => url.startsWith('https://content')

export const getPathWithoutQuery = (url: string) => url.split('?')[0]
