import { GetServerSidePropsContext } from 'next'
import { parseCookies, setCookie } from 'nookies'

const ActiveExperimentsKEY = 'ACTIVE_EXPERIMENTS'

export type ActiveExperiments = Record<ExperimentKey, ExperimentVariant>

export enum ExperimentKey {
    test = 'TEST',
    showPreventAccessDialogProgressBar = 'SHOW_PREVENT_ACCESS_DIALOG_PROGRESS_BAR',
    showSignupBannerOnScroll = 'SHOW_SIGNUP_BANNER_ON_SCROLL',
    mobileKnowThumbnailAddons = 'MOBILE_KNOW_THUMBNAIL_ADDONS',
    showScrollableBreadcrumbs = 'SHOW_SCROLLABLE_BREADCRUMBS',
}

export enum ExperimentVariant {
    testBaseline = 'TEST_0',
    testVariant = 'TEST_1',
    showPreventAccessDialogProgressBarVariant0 = 'SHOW_PREVENT_ACCESS_DIALOG_PROGRESS_BAR_V2_VARIANT_0',
    showPreventAccessDialogProgressBarVariant1 = 'SHOW_PREVENT_ACCESS_DIALOG_PROGRESS_BAR_V2_VARIANT_1',
    showSignupBannerOnScrollVariant0 = 'SHOW_SIGNUP_BANNER_ON_SCROLL_VARIANT_0',
    showSignupBannerOnScrollVariant1 = 'SHOW_SIGNUP_BANNER_ON_SCROLL_VARIANT_1',
    mobileKnowThumbnailAddonsVariant0 = 'MOBILE_KNOW_THUMBNAIL_ADDONS_VARIANT_0',
    mobileKnowThumbnailAddonsVariant1 = 'MOBILE_KNOW_THUMBNAIL_ADDONS_VARIANT_1',
    mobileKnowThumbnailAddonsVariant2 = 'MOBILE_KNOW_THUMBNAIL_ADDONS_VARIANT_2',
    mobileKnowThumbnailAddonsVariant3 = 'MOBILE_KNOW_THUMBNAIL_ADDONS_VARIANT_3',
    showScrollableBreadcrumbsVariant0 = 'SHOW_SCROLLABLE_BREADCRUMBS_VARIANT_0',
    showScrollableBreadcrumbsVariant1 = 'SHOW_SCROLLABLE_BREADCRUMBS_VARIANT_1',
}

function getRandomVariant(variants: ExperimentVariant[]): ExperimentVariant {
    return variants[Math.floor(Math.random() * variants.length)]
}

function getStoredVariant(storedVariants: string[], variants: ExperimentVariant[]): ExperimentVariant | null {
    for (const variant of variants) {
        if (storedVariants.includes(variant)) {
            return variant
        }
    }

    return null
}

function getExperimentVariant(possibleVariants: ExperimentVariant[], storedVariants: string[]): ExperimentVariant {
    const storedVariant = getStoredVariant(storedVariants, possibleVariants)
    if (storedVariant !== null) {
        return storedVariant
    } else {
        return getRandomVariant(possibleVariants)
    }
}

export function getActiveExperiments(ctx?: GetServerSidePropsContext): ActiveExperiments {
    const storedVariants = getActiveExperimentsStore(ctx)

    const activeExperiments: ActiveExperiments = {
        TEST: getExperimentVariant([ExperimentVariant.testBaseline, ExperimentVariant.testVariant], storedVariants),
        SHOW_PREVENT_ACCESS_DIALOG_PROGRESS_BAR: getExperimentVariant(
            [
                ExperimentVariant.showPreventAccessDialogProgressBarVariant0,
                ExperimentVariant.showPreventAccessDialogProgressBarVariant1,
            ],
            storedVariants
        ),
        SHOW_SIGNUP_BANNER_ON_SCROLL: getExperimentVariant(
            [ExperimentVariant.showSignupBannerOnScrollVariant0, ExperimentVariant.showSignupBannerOnScrollVariant1],
            storedVariants
        ),
        MOBILE_KNOW_THUMBNAIL_ADDONS: getExperimentVariant(
            [
                ExperimentVariant.mobileKnowThumbnailAddonsVariant0,
                ExperimentVariant.mobileKnowThumbnailAddonsVariant1,
                ExperimentVariant.mobileKnowThumbnailAddonsVariant2,
                ExperimentVariant.mobileKnowThumbnailAddonsVariant3,
            ],
            storedVariants
        ),
        SHOW_SCROLLABLE_BREADCRUMBS: getExperimentVariant(
            [ExperimentVariant.showScrollableBreadcrumbsVariant0, ExperimentVariant.showScrollableBreadcrumbsVariant1],
            storedVariants
        ),
    }

    storeActiveExperiments(Object.values(activeExperiments), ctx)

    return activeExperiments
}

export function getActiveExperimentsVariants() {
    const activeExperiments = getActiveExperiments()
    return Object.values(activeExperiments)
}

function isValidExperiment(experiment: ExperimentVariant, possibleVariants: ExperimentVariant[]): boolean {
    return possibleVariants.includes(experiment)
}

function cachedExperimentsAreValid(cachedExperiments: ActiveExperiments | null): boolean {
    if (!cachedExperiments) return false

    const allPossibleVariants = Object.values(ExperimentVariant)
    return Object.values(cachedExperiments).every((variant) => isValidExperiment(variant, allPossibleVariants))
}

// Active Experiments
export function getActiveExperimentsStore(ctx?: GetServerSidePropsContext): string[] {
    const cookies = parseCookies(ctx)
    const item = cookies[ActiveExperimentsKEY]
    if (!item) {
        return []
    }
    return item.split(',')
}

export const storeActiveExperiments = (experiments: string[], ctx?: GetServerSidePropsContext) => {
    const stringifiedValue = experiments.join(',')
    setCookie(ctx, ActiveExperimentsKEY, stringifiedValue, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
    })
}
