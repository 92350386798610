import taxonomyJson from './taxonomy.json'
import { parseLocale } from '../localization'
import { SubjectWithTopics } from 'interfaces/Taxonomy'
import { getLocalizedCountryOfOrigin } from '../country'

export const getTaxonomyCountryConfig = (locale?: string): SubjectWithTopics[] => {
    const { countryCode } = parseLocale(locale)

    const localizedCountryCode = getLocalizedCountryOfOrigin(countryCode)

    const countries = taxonomyJson.content
    const subjects = countries.find((country) => country.countryCode === localizedCountryCode)?.subjects

    if (!subjects) {
        return []
    }

    return subjects
}
