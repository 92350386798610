import { Language } from 'interfaces/Language'
import { Locale, ParsedLocale } from './localization'
import { CountryCode } from 'interfaces/Country'

export function returnLanguageSwitcherLabelByContentLanguage(interfaceLanguageCode: Locale | Language) {
    switch (interfaceLanguageCode) {
        case Locale.GermanGermany:
            return 'Deutsch'
        case Locale.EnglishUnitedStates:
        case Locale.EnglishUnitedKingdom:
            return 'English'
        case Locale.FrenchFrance:
            return 'Français'
        case Locale.PolishPoland:
            return 'Polski'
        case Locale.ItalianItaly:
            return 'Italiano'
        case Locale.SpainSpanish:
            return 'Español'
        case Locale.TurkeyTurkish:
            return 'Türkçe'
    }
    throw new Error(`Unknown language ${interfaceLanguageCode}`)
}

export function returnLanguageSwitcherLabelByCountryCode(countyCode: CountryCode) {
    switch (countyCode) {
        case CountryCode.Switzerland:
        case CountryCode.Germany:
        case CountryCode.Austria:
            return 'Deutschland'
        case CountryCode.UnitedStates:
            return 'United States'
        case CountryCode.UnitedKingdom:
            return 'United Kingdom'
        case CountryCode.France:
            return 'France'
        case CountryCode.Italy:
            return 'Italia'
        case CountryCode.Poland:
            return 'Polska'
        case CountryCode.Colombia:
            return 'Colombia'
        case CountryCode.Turkey:
            return 'Türkiye'
        case CountryCode.Spain:
            return 'España'
        case CountryCode.Mexico:
            return 'México'
    }
    throw new Error(`Unknown country code ${countyCode}`)
}

export function returnCountrySwitcherLabelByContentLanguage({ contentLanguageCode }: ParsedLocale) {
    switch (contentLanguageCode) {
        case Language.German:
            return 'Deutschland'
        case Language.BritishEnglish:
            return 'United Kingdom'
        case Language.French:
            return 'France'
        case Language.Polish:
            return 'Polska'
        case Language.Italian:
            return 'Italia'
        case Language.AmericanEnglish:
            return 'United States'
        case Language.Spanish:
            return 'España'
        case Language.ColombiaSpanish:
            return 'Colombia'
        case Language.Turkish:
            return 'Türkiye'
        case Language.MexicanSpanish:
            return 'México'
    }
    throw new Error(`Unknown language ${contentLanguageCode}`)
}

export function localizeNumber(number: number, language: Language): string {
    return number.toLocaleString(language)
}
