import config from 'lib/config'
import Head from 'next/head'

const MicrosoftClarityTag = () => {
    const tag = config.getMicrosoftClarityTag()
    return (
        <Head>
            <script
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: `
                    (function(c,l,a,r,i,t,y){
                        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                    })(window, document, "clarity", "script", "${tag}");                
                `,
                }}
            />
        </Head>
    )
}

export default MicrosoftClarityTag
