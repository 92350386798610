export enum Environment {
    Prod = 'prod',
    Staging = 'staging',
    Dev = 'dev',
    Local = 'local',
}

function getVariable(value: string | undefined, name: string) {
    if (!value) {
        throw `Environment variable ${name} not set`
    }
    return value
}

export function getEnvironment() {
    switch (process.env.NEXT_PUBLIC_ENV) {
        case 'prod':
            return Environment.Prod
        case 'staging':
            return Environment.Staging
        case 'dev':
            return Environment.Dev
        default:
            return Environment.Local
    }
}

function getApiBaseUrl() {
    const region = getDataCenterRegion()

    if (getEnvironment() === Environment.Prod) {
        return `https://apiedge-${region}.knowunity.com`
    }
    return `https://apiedge-${region}.knowunity.dev`
}

function getAnalyticsServiceBaseUrl() {
    const region = getDataCenterRegion()

    if (getEnvironment() === Environment.Prod) {
        return `https://a-${region}.knowunity.com`
    }
    return `https://a-${region}.knowunity.dev`
}

function getGlobalAuthServiceBaseUrl() {
    if (getEnvironment() === Environment.Prod) {
        return getVariable(
            process.env.NEXT_PUBLIC_GLOBAL_AUTHENTICATION_BASE_URL_PROD,
            'NEXT_PUBLIC_GLOBAL_AUTHENTICATION_BASE_URL_PROD'
        )
    }
    return getVariable(
        process.env.NEXT_PUBLIC_GLOBAL_AUTHENTICATION_BASE_URL_DEV,
        'NEXT_PUBLIC_GLOBAL_AUTHENTICATION_BASE_URL_DEV'
    )
}

function getGoogleClientId() {
    return getVariable(process.env.NEXT_PUBLIC_AUTH_GOOGLE_CLIENT_ID, 'NEXT_PUBLIC_AUTH_GOOGLE_CLIENT_ID')
}

function getTikTokAccessToken() {
    return getVariable(process.env.NEXT_PUBLIC_TIKTOK_ACCESS_TOKEN, 'NEXT_PUBLIC_TIKTOK_ACCESS_TOKEN')
}

function getTikTokPixelId() {
    return getVariable(process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID, 'NEXT_PUBLIC_TIKTOK_PIXEL_ID')
}

function getAppleClientId() {
    return getVariable(process.env.NEXT_PUBLIC_AUTH_APPLE_CLIENT_ID, 'NEXT_PUBLIC_AUTH_APPLE_CLIENT_ID')
}

function getApiSecretCode() {
    if (getEnvironment() === Environment.Prod) {
        return getVariable(
            process.env.NEXT_PUBLIC_API_CLIENT_VALIDATION_SECRET_PROD,
            'NEXT_PUBLIC_API_CLIENT_VALIDATION_SECRET_PROD'
        )
    }
    return getVariable(
        process.env.NEXT_PUBLIC_API_CLIENT_VALIDATION_SECRET_DEV,
        'NEXT_PUBLIC_API_CLIENT_VALIDATION_SECRET_DEV'
    )
}

function getFacebookAccessToken() {
    return getVariable(process.env.NEXT_PUBLIC_FB_ACCESS_TOKEN, 'NEXT_PUBLIC_FB_ACCESS_TOKEN')
}

function getDataCenterRegion() {
    return getVariable(process.env.NEXT_PUBLIC_DATA_CENTER_REGION, 'NEXT_PUBLIC_DATA_CENTER_REGION')
}

function getFacebookPixelId() {
    return getVariable(process.env.NEXT_PUBLIC_FB_PIXEL_ID, 'NEXT_PUBLIC_FB_PIXEL_ID')
}

function getStripePublishableKey() {
    if (getEnvironment() === Environment.Prod) {
        return getVariable(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY_PROD, 'NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY_PROD')
    }
    return getVariable(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY_DEV, 'NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY_DEV')
}

function getHCaptchaKey() {
    if (getEnvironment() === Environment.Prod) {
        return getVariable(process.env.NEXT_PUBLIC_HCAPTCHA_KEY_PROD, 'NEXT_PUBLIC_HCAPTCHA_KEY_PROD')
    }
    return getVariable(process.env.NEXT_PUBLIC_HCAPTCHA_KEY_DEV, 'NEXT_PUBLIC_HCAPTCHA_KEY_DEV')
}

function getMicrosoftClarityTag() {
    if (getEnvironment() === Environment.Prod) {
        return getVariable(process.env.NEXT_PUBLIC_MICROSOFT_CLARITY_PROD, 'NEXT_PUBLIC_MICROSOFT_CLARITY_PROD')
    }
    return getVariable(process.env.NEXT_PUBLIC_MICROSOFT_CLARITY_DEV, 'NEXT_PUBLIC_MICROSOFT_CLARITY_DEV')
}

function getElfsightWidgetID() {
    return getVariable(process.env.NEXT_PUBLIC_ELFSIGHT_WIDGET_ID, 'NEXT_PUBLIC_ELFSIGHT_WIDGET_ID')
}

function getFacebookPageID() {
    return getVariable(process.env.NEXT_PUBLIC_FACEBOOK_PAGE_ID, 'NEXT_PUBLIC_FACEBOOK_PAGE_ID')
}

function getPayPalClientID() {
    if (getEnvironment() === Environment.Prod) {
        return getVariable(process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID_PROD, 'NEXT_PUBLIC_PAYPAL_CLIENT_ID_PROD')
    }
    return getVariable(process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID_DEV, 'NEXT_PUBLIC_PAYPAL_CLIENT_ID_DEV')
}

export function getAdjustTrackerCodeAndroid() {
    return getVariable(process.env.NEXT_PUBLIC_ADJUST_TRACKER_CODE_ANDROID, 'NEXT_PUBLIC_ADJUST_TRACKER_CODE_ANDROID')
}

export function getAdjustTrackerCodeIos() {
    return getVariable(process.env.NEXT_PUBLIC_ADJUST_TRACKER_CODE_IOS, 'NEXT_PUBLIC_ADJUST_TRACKER_CODE_IOS')
}

// Adjust Android Baseline
export function getAdjustTrackerCppCodeAndroidBaseline() {
    return getVariable(
        process.env.NEXT_PUBLIC_ADJUST_CPP_TRACKER_CODE_ANDROID_BASELINE,
        'NEXT_PUBLIC_ADJUST_CPP_TRACKER_CODE_ANDROID_BASELINE'
    )
}
export function getAdjustTrackerCppRedirectAndroidBaseline() {
    return getVariable(
        process.env.NEXT_PUBLIC_ADJUST_CPP_TRACKER_REDIRECT_ANDROID_BASELINE,
        'NEXT_PUBLIC_ADJUST_CPP_TRACKER_REDIRECT_ANDROID_BASELINE'
    )
}

// Adjust Android Variant
export function getAdjustTrackerCppCodeAndroidVariant() {
    return getVariable(
        process.env.NEXT_PUBLIC_ADJUST_CPP_TRACKER_CODE_ANDROID_VARIANT,
        'NEXT_PUBLIC_ADJUST_CPP_TRACKER_CODE_ANDROID_VARIANT'
    )
}
export function getAdjustTrackerCppRedirectAndroidVariant() {
    return getVariable(
        process.env.NEXT_PUBLIC_ADJUST_CPP_TRACKER_REDIRECT_ANDROID_VARIANT,
        'NEXT_PUBLIC_ADJUST_CPP_TRACKER_REDIRECT_ANDROID_VARIANT'
    )
}

// Adjust iOS Baseline
export function getAdjustTrackerCppCodeIosBaseline() {
    return getVariable(
        process.env.NEXT_PUBLIC_ADJUST_CPP_TRACKER_CODE_IOS_BASELINE,
        'NEXT_PUBLIC_ADJUST_CPP_TRACKER_CODE_IOS_BASELINE'
    )
}
export function getAdjustTrackerCppRedirectIosBaseline() {
    return getVariable(
        process.env.NEXT_PUBLIC_ADJUST_CPP_TRACKER_REDIRECT_IOS_BASELINE,
        'NEXT_PUBLIC_ADJUST_CPP_TRACKER_REDIRECT_IOS_BASELINE'
    )
}

// Adjust iOS Variant
export function getAdjustTrackerCppCodeIosVariant() {
    return getVariable(
        process.env.NEXT_PUBLIC_ADJUST_CPP_TRACKER_CODE_IOS_VARIANT,
        'NEXT_PUBLIC_ADJUST_CPP_TRACKER_CODE_IOS_VARIANT'
    )
}
export function getAdjustTrackerCppRedirectIosVariant() {
    return getVariable(
        process.env.NEXT_PUBLIC_ADJUST_CPP_TRACKER_REDIRECT_IOS_VARIANT,
        'NEXT_PUBLIC_ADJUST_CPP_TRACKER_REDIRECT_IOS_VARIANT'
    )
}

// Strapi
export function getStrapiBaseURL() {
    return getVariable(process.env.NEXT_PUBLIC_STRAPI_BASE_URL, 'NEXT_PUBLIC_STRAPI_BASE_URL')
}
export function getStrapiApiKey() {
    return getVariable(process.env.NEXT_PUBLIC_STRAPI_API_KEY, 'NEXT_PUBLIC_STRAPI_API_KEY')
}

// GTM
export function getGoogleTagManagerId() {
    return getVariable(process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID, 'NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID')
}

// Sentry
export function getSentryDSN() {
    return getVariable(process.env.NEXT_PUBLIC_SENTRY_DSN, 'NEXT_PUBLIC_SENTRY_DSN')
}

export default {
    Environment,
    getEnvironment,
    getApiBaseUrl,
    getAnalyticsServiceBaseUrl,
    getGoogleClientId,
    getAppleClientId,
    getStripePublishableKey,
    getHCaptchaKey,
    getElfsightWidgetID,
    getTikTokAccessToken,
    getTikTokPixelId,
    getFacebookAccessToken,
    getFacebookPixelId,
    getGlobalAuthenticationBaseUrl: getGlobalAuthServiceBaseUrl,
    getMicrosoftClarityTag,
    getFacebookPageID,
    getPayPalClientID,
    getDataCenterRegion,
    getApiSecretCode,
    getStrapiBaseURL,
    getStrapiApiKey,
    getSentryDSN,
    getGoogleTagManagerId,
}
