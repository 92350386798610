import { AuthToken } from 'interfaces/AuthToken'
import { Knower } from 'interfaces/Knower'
import { User } from 'interfaces/User'

export interface State {
    oauthToken: AuthToken | null
    knower: Knower | null
    user: User | null
    overwriteShowCookieBanner: boolean
}

export const SET_OAUTH_TOKEN = 'SET_OAUTH_TOKEN'
export interface SetOauthTokenAction {
    type: typeof SET_OAUTH_TOKEN
    payload: AuthToken | null
}

export const SET_KNOWER = 'SET_KNOWER'
export interface SetKnowerAction {
    type: typeof SET_KNOWER
    payload: Knower | null
}

export const SET_USER = 'SET_USER'
export interface SetUserAction {
    type: typeof SET_USER
    payload: User | null
}

export const SET_OVERWRITE_SHOW_COOKIE_BANNER = 'SET_OVERWRITE_SHOW_COOKIE_BANNER'
export interface SetOverwriteShowCookieBanner {
    type: typeof SET_OVERWRITE_SHOW_COOKIE_BANNER
    payload: boolean
}

export type ActionTypes = SetOauthTokenAction | SetKnowerAction | SetUserAction | SetOverwriteShowCookieBanner
