import ApiService from './ApiService'
import { Country, CountryCode } from 'interfaces/Country'
import { Region } from 'interfaces/Region'
import { Language } from 'interfaces/Language'
import { CountryLanguageConfig } from 'lib/constants/countryLanguageConfig'
import { ExamBoard } from 'interfaces/ExamBoard'
import { ExamType } from 'interfaces/ExamType'

const CountryService = {
    list() {
        return ApiService.get('/countries').then((response) => {
            return response.data.content as Country[]
        })
    },

    listRegions(id: number) {
        return ApiService.get(`/countries/${id}/regions`).then((response) => {
            return response.data.content as Region[]
        })
    },

    getCountryConfig(country: number | CountryCode, language: number | Language) {
        return ApiService.get(`/countries/${country}/languages/${language}/config`).then((response) => {
            return response.data as CountryLanguageConfig
        })
    },

    listExamBoards(country: number | CountryCode) {
        return ApiService.get(`countries/${country}/exam-boards`).then((response) => {
            return response.data.content as ExamBoard[]
        })
    },

    listExamTypes(country: number | CountryCode) {
        return ApiService.get(`countries/${country}/exam-types`).then((response) => {
            return response.data.content as ExamType[]
        })
    },

    getByIp() {
        return ApiService.get('/countries/by-ip').then((response) => {
            return response.data as Country
        })
    },
}

export default CountryService
