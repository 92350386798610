import { SessionTrackingSnippet } from 'interfaces/TrackingSnippets'
import { Attribution } from './attribution/attribution'
import { minutesBetweenDates } from '../formatting/datetime'
import {
    generateSessionSnippetObject,
    getSessionGenerationTimestamp,
    getSessionTrackingUuid,
    storeSessionGenerationTimestamp,
    storeSessionTrackingUuid,
} from './storage'
import { uuidv4 } from 'lib/tracking/attribution/uuid'

let cachedSessionSnippet: SessionTrackingSnippet | null = null

const REFRESH_TIMESTAMP_MINUTES_INTERVAL = 30

export function getSessionSnippet(attributionData: Attribution): SessionTrackingSnippet {
    const lastTimestamp = getSessionGenerationTimestamp()
    const currentTimestamp = new Date().toISOString()
    let currentSessionUuid = getSessionTrackingUuid()

    const isSessionObjectStored = currentSessionUuid

    const isSessionObjectYoungerThan30Minutes =
        lastTimestamp && minutesBetweenDates(lastTimestamp, currentTimestamp) < REFRESH_TIMESTAMP_MINUTES_INTERVAL

    const issessionObjectValid = isSessionObjectStored && isSessionObjectYoungerThan30Minutes

    // Checking if session snippet exists
    if (issessionObjectValid) {
        if (cachedSessionSnippet) return cachedSessionSnippet
        else {
            const information = generateSessionSnippetObject(currentSessionUuid!, attributionData)
            cachedSessionSnippet = information
            return information
        }
    } else {
        currentSessionUuid = uuidv4()
    }

    // Session snippet doesn't exist. Create and store new snippet
    const information = generateSessionSnippetObject(currentSessionUuid, attributionData)

    cachedSessionSnippet = information

    storeSessionTrackingUuid(currentSessionUuid)
    storeSessionGenerationTimestamp(new Date().toISOString())
    return information
}
