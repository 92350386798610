import attribution from 'lib/tracking/attribution/attribution'
import axios from 'axios'
import { getCookieConsent } from 'lib/tracking/storage'

export enum FacebookEventTypes {
    ViewContent = 'ViewContent',
    AddToCart = 'AddToCart',
    InitiateCheckout = 'InitiateCheckout',
    Purchase = 'Purchase',
    PageView = 'PageView',
    Lead = 'Lead',
    CompleteRegistration = 'CompleteRegistration',
    SubmitApplication = 'SubmitApplication',
}

type FacebookActionSource = 'email' | 'website' | 'phone_call' | 'chat' | 'physical_store' | 'system_generated' | 'other'

export type FacebookEvent = {
    data: [
        {
            event_name: FacebookEventTypes | string
            event_time: number // format Unix's timestamp: 1633552688
            event_id: string | number
            event_source_url?: string
            action_source: FacebookActionSource
            user_data: {
                client_ip_address?: string
                client_user_agent?: string
                em?: string[] | string
                ph?: string[] | string
                fbc?: string
                fbp?: string
            }
            custom_data?: {
                value?: number
                currency?: string
                content_ids?: string[]
                content_type?: string
            }
            // A flag that indicates we should not use this event for ads delivery optimization. If set to true, we only use the event for attribution.
            opt_out?: boolean
        }
    ]
    test_event_code?: string
}

const facebookEventQueue: {
    type: FacebookEventTypes | string
    facebookClickId?: string
}[] = []

export function trackFacebookEvent(eventName: FacebookEventTypes | string, facebookClickId?: string, testCode?: string) {
    const attributionData = attribution.getAttribution()

    const consentCookie = getCookieConsent()

    if (!consentCookie?.statistics || !consentCookie?.marketing) {
        facebookEventQueue.push({ type: eventName, facebookClickId: facebookClickId ?? attributionData.facebookClickId })
        return
    }

    axios
        .post(
            '/api/fb-events',
            {
                eventName,
                testCode,
                fbc: facebookClickId ?? attributionData.facebookClickId,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
        .catch((error) => {
            console.error(`Server Side Event: ${eventName} (${error.status})`)
        })
}

export const emptyFacebookEventQueue = () => {
    if (!facebookEventQueue?.length) return

    for (const event of facebookEventQueue) {
        trackFacebookEvent(event.type, event.facebookClickId)
    }
    // https://stackoverflow.com/questions/1232040/how-do-i-empty-an-array-in-javascript
    facebookEventQueue.length = 0
}
