import { State, ActionTypes, SET_OAUTH_TOKEN, SET_KNOWER, SET_USER, SET_OVERWRITE_SHOW_COOKIE_BANNER } from './types'

enum LocalStorageKey {
    OauthToken = 'OAUTH_TOKEN',
    Knower = 'KNOWER',
    User = 'USER',
}

function localStorageGet(key: LocalStorageKey) {
    const item = localStorage.getItem(key)
    if (!item) {
        return null
    }

    if (!item.startsWith('{') || !item.endsWith('}')) {
        return null
    }

    return JSON.parse(item)
}

export const initialState: State =
    typeof window !== 'undefined'
        ? {
              oauthToken: localStorageGet(LocalStorageKey.OauthToken),
              knower: localStorageGet(LocalStorageKey.Knower),
              user: localStorageGet(LocalStorageKey.User),
              overwriteShowCookieBanner: false,
          }
        : {
              oauthToken: null,
              knower: null,
              user: null,
              overwriteShowCookieBanner: false,
          }

const reducer = (state = initialState, action: ActionTypes): State => {
    switch (action.type) {
        case SET_OAUTH_TOKEN:
            localStorage.setItem(LocalStorageKey.OauthToken, JSON.stringify(action.payload))
            return {
                ...state,
                oauthToken: action.payload,
            }

        case SET_KNOWER:
            localStorage.setItem(LocalStorageKey.Knower, JSON.stringify(action.payload))
            return {
                ...state,
                knower: action.payload,
            }

        case SET_USER:
            localStorage.setItem(LocalStorageKey.User, JSON.stringify(action.payload))
            return {
                ...state,
                user: action.payload,
            }

        case SET_OVERWRITE_SHOW_COOKIE_BANNER:
            return {
                ...state,
                overwriteShowCookieBanner: action.payload,
            }

        default:
            return state
    }
}

export type AppState = ReturnType<typeof reducer>
export default reducer
