import styled from 'styled-components'

interface Props {
    readonly gap?: number
}

const Row = styled.div<Props>`
    display: flex;
    gap: ${(props) => props.gap ?? 16}px;
    align-items: center;
`

export default Row
