import attribution from 'lib/tracking/attribution/attribution'
import axios from 'axios'
import { getCookieConsent } from 'lib/tracking/storage'

export enum TikTokEventType {
    AddPaymentInfo = 'AddPaymentInfo',
    AddToCart = 'AddToCart',
    AddToWishlist = 'AddToWishlist',
    ClickButton = 'ClickButton',
    CompletePayment = 'CompletePayment',
    CompleteRegistration = 'CompleteRegistration',
    Contact = 'Contact',
    Download = 'Download',
    InitiateCheckout = 'InitiateCheckout',
    PlaceAnOrder = 'PlaceAnOrder',
    Search = 'Search',
    SubmitForm = 'SubmitForm',
    Subscribe = 'Subscribe',
    ViewContent = 'ViewContent',
}

export type TiktokEvent = {
    pixel_code: string
    event: string
    event_id: string
    timestamp: string
    test_event_code?: string
    context: {
        ad: {
            callback: string
        }
        page: {
            url: string
            referrer: string
        }
        user?: {
            external_id?: string
            phone_number?: string
            email?: string
        }
        user_agent: string
        ip: string
    }
    properties?: {
        contents: [
            {
                price: number
                quantity: number
                content_type: string
                content_id: string
            }
        ]
        currency: string
        value: number
    }
}

const tiktokEventQueue: {
    type: TikTokEventType
    tiktokClickId?: string
}[] = []

export function trackTikTokEvent(event: TikTokEventType, tiktokClickId?: string, testCode?: string) {
    const attributionData = attribution.getAttribution()
    const consentCookie = getCookieConsent()

    if (!consentCookie?.statistics || !consentCookie?.marketing) {
        tiktokEventQueue.push({ type: event, tiktokClickId: tiktokClickId ?? attributionData.tiktokClickId })
        return
    }

    axios
        .post(
            '/api/tiktok-events',
            {
                eventName: event,
                testCode,
                callback: tiktokClickId ?? attributionData.tiktokClickId,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
        .catch((error) => {
            console.error(`Server Side Event: ${event} (${error.status})`)
        })
}

export const emptyTiktokEventQueue = () => {
    if (!tiktokEventQueue?.length) return

    for (const event of tiktokEventQueue) {
        trackTikTokEvent(event.type, event.tiktokClickId)
    }
    // https://stackoverflow.com/questions/1232040/how-do-i-empty-an-array-in-javascript
    tiktokEventQueue.length = 0
}
