import { getDynamicAppLink } from 'lib/features/app-links'
import { QRCodeSVG } from 'qrcode.react'
import styled from 'styled-components'

interface Props {
    referrerScreen: string
    backgroundColor?: string
    codeColor?: string
    size?: number
}

const Container = styled.div`
    display: grid;
    gap: 25px;
    width: fit-content;
    height: auto;
    position: relative;
    .code {
        border-radius: 10px;
        z-index: 1;
    }
`

const DownloadQRCode = ({ backgroundColor, codeColor, referrerScreen, size }: Props) => {
    const url = getDynamicAppLink('', referrerScreen)

    return (
        <Container>
            <QRCodeSVG value={url} size={size ?? 175} bgColor={backgroundColor} fgColor={codeColor} className="code" />
        </Container>
    )
}

export default DownloadQRCode
