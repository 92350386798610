import React, { useState, useEffect } from 'react'

export const ClientOnly: React.FC<Record<string, unknown>> = ({ children, ...delegated }) => {
    const [hasMounted, setHasMounted] = useState(false)

    useEffect(() => {
        setTimeout(() => setHasMounted(true), 0)
    }, [])

    if (!hasMounted) {
        return null
    }

    return <React.Fragment {...delegated}>{children}</React.Fragment>
}
