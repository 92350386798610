import { deviceSize } from 'lib/constants/GridBreakpoints'
import { appEditProfileRoute, appPayoutsRoute, profilePayoutRoute, profileRoute } from 'lib/constants/routes'
import useMedia from 'lib/hooks/useMedia'
import { useTranslation } from 'next-i18next'
import router from 'next/router'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/reducer'
import { ApiError } from 'services/ApiService'
import TokenService from 'services/TokenService'
import DropDown from './DropDown'

interface Props {
    dropdownRef: React.RefObject<HTMLLIElement>
    open: boolean
    handleCloseDropdown: () => void
    handleLinksWithinWebapp?: boolean
    position?: {
        xOffset: number
        yOffset: number
    }
}

const UserProfileDropDown = ({ dropdownRef, open, handleCloseDropdown, position, handleLinksWithinWebapp }: Props) => {
    const authenticatedKnower = useSelector((state: AppState) => state.knower)
    const { t: tCommon } = useTranslation('common')
    const md = useMedia(deviceSize.md)

    const logout = () => {
        TokenService.logout()
            .then(() => {
                router.push('/auth/signin')
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknownError(tCommon, 'auth.logout')
                } else {
                    throw error
                }
            })
    }

    const dropdownItems = [
        {
            name: tCommon('common/navBarEditProfileLink'),
            to: handleLinksWithinWebapp ? appEditProfileRoute : profileRoute,
            condition: true,
            openInNewTab: false,
        },
        {
            name: tCommon('common/navBarProfilePayoutsLink'),
            to: handleLinksWithinWebapp ? appPayoutsRoute : profilePayoutRoute,
            condition: !!authenticatedKnower,
            openInNewTab: false,
        },
        {
            name: tCommon('common/navBarLogoutLink'),
            to: '',
            condition: true,
            onClick: logout,
            openInNewTab: false,
        },
    ]

    return (
        <DropDown
            dropdownRef={dropdownRef}
            open={open}
            handleCloseDropdown={handleCloseDropdown}
            listItems={dropdownItems}
            position={
                position ?? {
                    xOffset: md ? 205 : 200,
                    yOffset: md ? -20 : -5,
                }
            }
        />
    )
}

export default UserProfileDropDown
