import { Language } from 'interfaces/Language'

// TODO: Make `locale` a required parameter
export function formatDate(date: Date | string, interfaceLanguage?: Language): string {
    // 7.2.2021
    if (!(date instanceof Date)) {
        date = new Date(date)
    }
    if (!interfaceLanguage) {
        interfaceLanguage = Language.German
    }
    return date.toLocaleDateString(interfaceLanguage)
}

/**
 * @deprecated The method should not be used since it is not localized
 */
export function formatDateTime(date: Date | string, hideLocaleSufix?: boolean): string {
    // 07.02.2021, 12:59 Uhr
    if (!(date instanceof Date)) {
        date = new Date(date)
    }

    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = String(date.getFullYear())
    const hour = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')

    return `${day}.${month}.${year}, ${hour}:${minutes} ${!hideLocaleSufix ? 'Uhr' : ''}`
}

export const minutesBetweenDates = (startTime: string | Date, endTime: string | Date) =>
    Math.abs(new Date(endTime).getTime() - new Date(startTime).getTime()) / (60 * 1000)

export const changeDate = (currentDate: Date, numberOfDays: number) =>
    new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + numberOfDays)

export const timeSince = (timeString: string) => {
    const diff = (new Date().getTime() - new Date(timeString).getTime()) / (86400 * 1000)

    if (diff < 1) return `vor ${Math.floor(diff / 24)} Stunden`
    if (diff > 1 && diff < 30) return `vor ${Math.floor(diff)} Tagen`
    if (diff > 30 && diff < 365) return `vor ${Math.floor(diff / 30)} Monaten`
    if (diff > 365) return `vor ${Math.floor(diff / 365)} Jahren`
}

export const timeBetweenDates = (startTime: string | Date, endTime: string | Date) =>
    Math.abs(new Date(endTime).getTime() - new Date(startTime).getTime()) / (3600 * 1000)

export const secondsBetweenDates = (t1: string | Date, t2: string | Date) => {
    const diff = new Date(t1).getTime() - new Date(t2).getTime()
    const diffInSeconds = diff / 1000
    return Math.abs(diffInSeconds)
}

export function getWeekDays(current: Date) {
    const week = []

    if (current.getDay() === 0) {
        current.setDate(current.getDate() - 6)
    } else {
        current.setDate(current.getDate() - current.getDay() + 1)
    }
    for (let i = 0; i < 7; i++) {
        week.push({
            date: new Date(new Date(current).setHours(8, 0, 0, 0)),
            day: new Date(current).toLocaleString('de-DE', { weekday: 'short' }),
        })
        current.setDate(current.getDate() + 1)
    }
    return week
}

export function isSameDay(dateString: string | Date, matchDate: string | Date) {
    return new Date(dateString).toDateString() === new Date(matchDate).toDateString()
}

export const formatHour = (hour: number) => `${hour.toString().padStart(2, '0')}:00`

export const formatHourMinuteString = (dateString: string | Date) =>
    new Date(dateString).toLocaleTimeString('de-DE').slice(0, 5)
