import { Attribution } from 'lib/tracking/attribution/attribution'
import { getLanguageByDomain } from 'lib/constants/language'
import { getCountryByDomain } from 'lib/features/country'
import { DeviceInformation, getDeviceInformation } from 'lib/tracking/attribution/device'
import { getPageTrackingSnippet, PageTrackingSnippet } from 'lib/tracking/pageTracking'
import { getSessionSnippet } from 'lib/tracking/sessionTracking'
import { CountryCode } from './Country'
import { Know } from './Know'
import { Language } from './Language'
import { User } from './User'
import { ChatMessage, ChatMessageTrackingSnippet, ChatTrackingSnippet, UserChat } from './UserChat'
import { SearchSuggestion } from './Search'
import { uuidv4 } from 'lib/tracking/attribution/uuid'
import { NextRequest } from 'next/server'

export const generateDeviceTrackingSnippet = (): DeviceInformation | null => {
    const information = getDeviceInformation()

    return information ?? null
}

export interface SearchTrackingSnippet {
    uuid: string
    term: string
    requestUuid: string
    resultPosition: number | null
    numberOfResults: number
    numberOfExactResults: number | null
    type: 'KNOW' | null
    origin: string | null
}

export interface FeedTrackingSnippet {
    uuid: string
    locationPageName: string
    locationPagePath: string
    resultPosition: number | null
    type: 'popular'
    contentType: 'KNOW'
    numberOfResults: number | null
    position: number
}

export interface KnowTrackingSnippet {
    uuid: string
    knowerUuid: string
    subjectIds: number[]
    subjectNames: string[]
    gradeIds: number[]
    gradeLevels: number[]
    publishedOn: string | null
    format: string
}

export interface AiChatOptions {
    studyBuddyUuid: string
}

export interface AiChatTrackingSnippet {
    studybuddyUuid: string
}

export interface SearchOptions {
    suggestion: SearchSuggestion
    resultPosition?: number
    numberOfExactResults?: number
    searchSessionUuid?: string
    origin?: string
    type?: 'KNOW'
}

export const generateKnowTrackingSnippet = (know: Know): KnowTrackingSnippet => ({
    uuid: know.uuid,
    knowerUuid: know.knower.uuid,
    subjectIds: know.subjects.map((subject) => subject.id),
    subjectNames: know.subjects.map((subject) => subject.name),
    gradeIds: know.grades.map((grade) => grade.id),
    gradeLevels: know.grades.map((grade) => grade.level),
    publishedOn: know.publishedOn,
    format: know.knowFormat.toLocaleLowerCase(),
})

export const generateAiChatTrackingSnippet = ({ studyBuddyUuid }: AiChatOptions): AiChatTrackingSnippet => ({
    studybuddyUuid: studyBuddyUuid,
})

export const generateChatTrackingSnippet = (chat: UserChat): ChatTrackingSnippet => ({
    uuid: chat.uuid,
    partnerUserUuid: chat?.chatPartner?.uuid ?? null,
})

const returnChatMessageContentType = (chatMessage: ChatMessage) => {
    if (chatMessage?.know) {
        return 'KNOW'
    } else if (chatMessage?.attachmentUrl) {
        return 'IMAGE'
    } else {
        return 'TEXT'
    }
}

const returnChatMessageSourceType = (chatMessage: ChatMessage) => {
    if (chatMessage.manualChatMessageUuid) {
        return 'MANUAL'
    } else {
        return 'USER_WRITTEN'
    }
}

export const generateChatMessageTrackingSnippet = (
    chatMessage: ChatMessage,
    chatPartnerUuid?: string | null
): ChatMessageTrackingSnippet => ({
    uuid: chatMessage.uuid,
    createdOn: chatMessage.createdOn,
    senderUserUuid: chatMessage.user.uuid,
    receiverUserUuid: chatPartnerUuid ?? null,
    contentType: returnChatMessageContentType(chatMessage),
    source: returnChatMessageSourceType(chatMessage),
})

export const generatePageTrackingSnippet = (serverSideRequest?: NextRequest): PageTrackingSnippet | null => {
    if (serverSideRequest) {
        const parsedUrl = new URL(serverSideRequest.url)

        return {
            path: parsedUrl.pathname + parsedUrl.search,
            name: null,
            previousName: null,
            previousPath: null,
        }
    }

    const isServerSide = typeof window === 'undefined'
    if (!isServerSide) {
        return getPageTrackingSnippet()
    }

    return null
}

export interface UserTrackingSnippet {
    uuid: string | null
    createdOn: string | null
    country: CountryCode | null
    interfaceLanguage: Language | null
    contentLanguage: Language | null
    region: string | null
    schoolType: string | null
    grade: string | null
    streak: number | null
    premiumStatus: string | null
    knowerUuid: string | null
    source: string | null
    sourceCampaign: string | null
    isKnower: boolean
}

export const generateUserTrackingSnippet = (user: User): UserTrackingSnippet => ({
    uuid: user.uuid,
    createdOn: user.createdOn,
    country: user.country?.code || null,
    interfaceLanguage: user.interfaceLanguage?.code || null,
    contentLanguage: user.primaryContentLanguage?.code || null,
    region: user.region?.code || null,
    schoolType: user.schoolType?.name || null,
    grade: user.grade?.level.toString() || null,
    streak: null,
    premiumStatus: getUserPremiumStatus(user),
    knowerUuid: user.knowerUuid,
    source: user.source,
    sourceCampaign: user.sourceCampaign,
    isKnower: user.isActiveKnower,
})

export const generateUserTrackingSnippetUnauthenticated = (attributionData: Attribution): UserTrackingSnippet => ({
    uuid: null,
    createdOn: null,
    country: getCountryByDomain(),
    interfaceLanguage: getLanguageByDomain(),
    contentLanguage: null,
    region: null,
    schoolType: null,
    grade: null,
    streak: null,
    premiumStatus: null,
    knowerUuid: null,
    source: attributionData.source ?? null,
    sourceCampaign: attributionData.sourceCampaign ?? null,
    isKnower: false,
})

export interface SessionTrackingSnippet {
    uuid: string | null
    source: string | null
    sourceCampaign: string | null
}

export interface WebsiteTrackingSnippet {
    domain: string | null
    referrer: string | null
    userAgent: string | null
}

export const generateWebsiteTrackingSnippet = (serverSideRequest?: NextRequest): WebsiteTrackingSnippet | null => {
    if (serverSideRequest) {
        // The domain can't be retrieved from the request url
        // https://github.com/vercel/next.js/issues/37536#issuecomment-1157000990
        const host = serverSideRequest.headers.get('Host')

        return {
            domain: host,
            referrer: serverSideRequest.referrer || null,
            userAgent: serverSideRequest.headers.get('user-agent') || null,
        }
    }

    const isServerSide = typeof window === 'undefined'
    if (!isServerSide) {
        return {
            domain: window.location.host,
            referrer: document.referrer || null,
            userAgent: navigator.userAgent,
        }
    }

    return null
}

function getUserPremiumStatus(user: User | null) {
    if (!user || user.premiumStatus === 'NONE') {
        return null
    }

    return user.premiumStatus.toLowerCase()
}

export const generateSessionSnippet = (attributionData: Attribution): SessionTrackingSnippet =>
    getSessionSnippet(attributionData)

export interface SearchEventOptions {
    suggestion: SearchSuggestion
    resultPosition?: number
    numberOfExactResults?: number
    searchSessionUuid?: string
    origin?: string
    type?: 'KNOW'
}

export const generateSearchTrackingSnippet = (options: SearchEventOptions): SearchTrackingSnippet => ({
    uuid: options?.searchSessionUuid ?? uuidv4(),
    term: options.suggestion?.text ?? null,
    requestUuid: uuidv4(),
    resultPosition: options?.resultPosition ?? null,
    numberOfResults: options.suggestion?.numberOfResults ?? null,
    numberOfExactResults: options?.numberOfExactResults ?? null,
    type: options.type ?? null,
    origin: options?.origin ?? null,
})

export interface FeedEventOptions {
    uuid: string
    locationPageName: string
    locationPagePath: string
    numberOfResults?: number
    resultPosition?: number
}

export const generateFeedTrackingSnippet = (options: FeedEventOptions): FeedTrackingSnippet => ({
    uuid: options.uuid,
    locationPageName: options?.locationPageName ?? '',
    locationPagePath: options?.locationPagePath ?? '',
    resultPosition: options?.resultPosition ?? null,
    type: 'popular',
    contentType: 'KNOW',
    numberOfResults: options?.numberOfResults ?? null,
    position: 0,
})
